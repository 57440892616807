import { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import classNames from 'classnames';
import { MdArrowBackIosNew } from 'react-icons/md';

import Circle from '@assets/circles.svg';
import Logo from '@assets/svg/logoText.svg';
import { ONBOARDING_STEPS_UI } from '@configs/onBoarding.config';

interface LayoutProps {
  children: ReactNode;
  onboardingStep: number;
  carouselIndex: number;
  isButtonEnabled: boolean;
  mobileNumber: string;
  setOnboardingStep: Dispatch<SetStateAction<number>>;
  setCarouselIndex: Dispatch<SetStateAction<number>>;
}

/**
 * wraps all the OnBoarding Steps Components
 */
const OnboardingLayout: FC<LayoutProps> = ({
  children,
  setOnboardingStep,
  onboardingStep,
  setCarouselIndex,
  carouselIndex,
}) => {
  let currentOnboardingStep = 1;
  if (navigator.cookieEnabled) {
    currentOnboardingStep = Number(localStorage.getItem('lastOnboardingStep'));
  }
  //  triggers when the user clicks on the back button
  const handlePrevious = () => {
    if (onboardingStep === ONBOARDING_STEPS_UI.stepPictureUploadTips && carouselIndex === 1) {
      setCarouselIndex(0);
    }
    if (onboardingStep === ONBOARDING_STEPS_UI.stepPictureUpload) {
      localStorage.setItem('lastOnboardingStep', String(ONBOARDING_STEPS_UI.stepPictureUpload));
    }

    setOnboardingStep(onboardingStep - 1);
  };

  return (
    <>
      <div className="relative flex flex-col flex-1 px-5 pt-4 overflow-hidden " id="layout">
        {/* svg circle shape 👇 */}
        <div className="absolute top-0 right-0 ">
          <Circle />
        </div>

        {/* top section */}
        <nav className="items-center justify-center pb-2 center">
          <MdArrowBackIosNew
            size={25}
            className={classNames(
              'absolute top-[13px] left-[14px]  text-grayIcon transition hover:text-primaryPink block cursor-pointer',
              {
                '!hidden':
                  onboardingStep === ONBOARDING_STEPS_UI.stepMobile ||
                  onboardingStep === ONBOARDING_STEPS_UI.stepCombine ||
                  currentOnboardingStep === ONBOARDING_STEPS_UI.stepPictureUpload,
              }
            )}
            onClick={handlePrevious}
          />
          <Logo />
        </nav>

        {/* main section*/}
        {children}
      </div>
    </>
  );
};

export default OnboardingLayout;
