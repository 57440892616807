import { HTTPError } from 'ky';
import { UseQueryOptions, useQuery } from 'react-query';
import { ReceivedRequestExpiryResponse } from 'types/ai-received';

import { ENDPOINTS } from '@configs/api.config';
import { Recommendation, User } from '@libs/types';
import client from '@utils/client';
import { clientConfig } from '@utils/client';
import { convertObjectKeysToCamelCase } from '@utils/formatter';

interface RecommendationsQuery {
  results: Recommendation[];
  next: null;
}

export const useGetRecommendations = <T = RecommendationsQuery>(
  options?: UseQueryOptions<T, any, Recommendation[]>
) => {
  const fetcher = () => client.get(ENDPOINTS.GET_RECOMMENDATIONS).json();

  return useQuery(ENDPOINTS.GET_RECOMMENDATIONS, fetcher, {
    // @ts-ignore : this is a bug in react-query
    select: (data) => data.results,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      // @ts-ignore :  type of error ?
      if (failureCount < 3 && error?.response?.status >= 500) return true; // retry on 5xx errors for 3 times
    },
    ...options,
  });
};

export const useGetNoOfRecommendationsLeft = (options?: UseQueryOptions) => {
  const fetcher = () => client.get(ENDPOINTS.GET_RECOMMENDATION_COUNT).json();

  return useQuery(ENDPOINTS.GET_RECOMMENDATION_COUNT, fetcher, options);
};

const transformUserObjectToCamelCase = (data) => convertObjectKeysToCamelCase(data);

// TODO : FIX THIS , this type is not user
const fetchUser = (): Promise<User> => client.get(ENDPOINTS.ME).json();

export const useGetUser = (options?: UseQueryOptions) =>
  useQuery<User, Error, User>(ENDPOINTS.ME, fetchUser, {
    // @ts-ignore : => FIX THIS
    select: (data) => transformUserObjectToCamelCase(data),
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
    retry: (failureCount, error) => {
      // @ts-ignore : type of error ?
      if (failureCount < 3 && error?.response?.status >= 500) return true; // retry on 5xx errors for 3 times
    },
    ...options,
  });

export const usePartnerPreference = (options?: UseQueryOptions) => {
  const fetcher = () => client.get(ENDPOINTS.PARTNER_PREFERENCE_NEW).json();

  const queryInfo = useQuery(ENDPOINTS.PARTNER_PREFERENCE_NEW, fetcher, {
    ...options,
  });

  return {
    ...queryInfo,
    data: convertObjectKeysToCamelCase(queryInfo.data),
  };
};
export const useGetActivePlans = (options?: UseQueryOptions) => {
  const fetcher = () => client.get(ENDPOINTS.GET_USER_ACTIVE_PLAN).json();

  const queryInfo = useQuery(ENDPOINTS.GET_USER_ACTIVE_PLAN, fetcher, {
    ...options,
  });

  return {
    ...queryInfo,
    data: convertObjectKeysToCamelCase(queryInfo.data),
  };
};

export const useRefreshRecommendations = (options?: UseQueryOptions) => {
  const fetcher = () => client.get(ENDPOINTS.REFRESH_RECOMMENDATIONS).json();

  // Note : The key of this query is same as the key of the query in useGetRecommendations as we will be using the same
  // query cache to store and retrieve the data.
  return useQuery(ENDPOINTS.REFRESH_RECOMMENDATIONS, fetcher, options);
};

export const useGetConfig = (options?: UseQueryOptions) => {
  const fetcher = () => clientConfig.get(ENDPOINTS.PRODUCTION_CONFIG).json();

  const queryInfo = useQuery(ENDPOINTS.PRODUCTION_CONFIG, fetcher, {
    staleTime: Infinity,
    ...options,
  });
  return {
    ...queryInfo,
    data: convertObjectKeysToCamelCase(queryInfo.data),
  };
};

//receive request expiry
export const useGetExpiryDays = (
  options?:
    | Omit<
        UseQueryOptions<
          ReceivedRequestExpiryResponse,
          HTTPError,
          ReceivedRequestExpiryResponse,
          Array<string>
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const fetchReceivedRequestExpiry = (): Promise<ReceivedRequestExpiryResponse> =>
    client.get(ENDPOINTS.GET_EXPIRY_STATUS).json();
  const queryInfo = useQuery<ReceivedRequestExpiryResponse, Error, ReceivedRequestExpiryResponse>(
    ENDPOINTS.GET_EXPIRY_STATUS,
    fetchReceivedRequestExpiry,
    { refetchOnWindowFocus: true, ...options }
  );
  return { ...queryInfo };
};

// Sparkles Config
