import { HTTPError } from 'ky';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'rc-slider/assets/index.css';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import toast from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-spring-bottom-sheet/dist/style.css';

import AnalyticsMangerProvider from '@analytics/AnalyticsManagerProvider';
import { LayoutWrapper } from '@components/Layout';
import ToasterWrapper from '@components/ToasterWrapper';
import { ErrorBoundary } from '@libs/bugsnag';
import '@styles/globals.css';
import { __DEV__ } from '@utils/general';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: (failureCount, error: HTTPError) => {
        if (failureCount < 3 && error?.response?.status >= 500) return true; // retry on 5xx errors for 3 times
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      onError: async (error: HTTPError) => {
        const data = await error.response?.json();
        toast.error(data.message || 'Oops! Something went wrong.');
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
    <ErrorBoundary>
      <Head>
        <title>Betterhalf web app</title>
        <link rel="shortcut icon" href="assets/images/favicon.png" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AnalyticsMangerProvider>
          {router.pathname === '/' ? (
            <Component {...pageProps} />
          ) : (
            <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
              <LayoutWrapper>
                <Component {...pageProps} />
              </LayoutWrapper>
            </DndProvider>
          )}
          {__DEV__ && <ReactQueryDevtools position="top-right" />}
          <ToasterWrapper />
        </AnalyticsMangerProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
