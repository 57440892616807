import { memo, useState } from 'react';

import { BottomSheet } from 'react-spring-bottom-sheet';

import AccordionWithChips, {
  AccordionWithChipsProps,
} from '@components/Accordion/AccordionWIthChips';
import ChipsContainer from '@components/ChipsContainer';
import { MOTHER_TONGUE_LIST } from '@configs/onBoarding.config';
import { Item } from '@libs/types';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const MotherTongue = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false);
  const [value, setValue] = useState(savedValue);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value);
    toggleBottomSheet(false);
  };

  const props: AccordionWithChipsProps = {
    accordionKey: accordionKey,
    list: MOTHER_TONGUE_LIST.slice(0, 6),
    title: 'Mother Tongue',
    placeholder: 'e.g. Hindi',
    columnSize: 3,
    // Dynamic Props
    isOpen: isAccordionOpen,
    value: convertToStartCase(value),
    selectedItem: value,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
    showSeeMore: true,
    onSeeMorePress: () => toggleBottomSheet(true),
  };

  return (
    <>
      <AccordionWithChips {...props} />
      <BottomSheet open={isBottomSheetOpen} onDismiss={() => toggleBottomSheet(false)}>
        <ChipsContainer
          list={MOTHER_TONGUE_LIST}
          onChipClickHandler={onItemSelect}
          headerText="Mother Tongue"
          selectedChip={value}
          columnSize={3}
        />
      </BottomSheet>
    </>
  );
};

export default memo(MotherTongue);
