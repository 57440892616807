import { Item } from '@libs/types';
import { clientSearch } from '@utils/client';

export const searchCollegeNames = async (text: string) => {
  const res: any = await clientSearch(`college?charlist=${text}`).json();
  return res.college_list.map((searchResult) => ({
    id: searchResult.id,
    value: searchResult.College.toLowerCase(),
    displayText: searchResult.College,
  }));
};

export const searchHighestQualification = async (text: string) => {
  const res: any = await clientSearch(`degree?charlist=${text}`).json();

  return res.degree_list.map((searchResult) => ({
    id: searchResult.id,
    value: searchResult.Name.toLowerCase(),
    displayText: searchResult.Name,
  }));
};

export const searchDesignationNames = async (text: string): Promise<Array<Item>> => {
  try {
    const res: any = await clientSearch(`designation?charlist=${text}`).json();

    return res.designation_list.map((searchResult) => ({
      id: searchResult.id,
      value: searchResult.designation?.toLowerCase(),
      displayText: searchResult.designation,
    }));
  } catch (error) {
    return error;
  }
};

export const searchCompanyNames = async (text: string) => {
  const res: any = await clientSearch(`company?charlist=${text}`).json();

  return res.company_list.map((searchResult) => ({
    id: searchResult.id,
    value: searchResult.Name.toLowerCase(),
    displayText: searchResult.Name,
  }));
};

export const searchCommunityNames = async (text: string) => {
  const res: any = await clientSearch(`community?charlist=${text}`).json();

  return res.community_list.map((searchResult) => ({
    id: searchResult.id,
    value: searchResult.community.toLowerCase(),
    displayText: searchResult.community,
  }));
};

// {
//   "geometry":{"type":"Point","coordinates":[23.2324214,87.8614793]},
//   "properties":{"city":"Kolkata","state":"West Bengal","country":"India","tag":"hometown"},
//   "type":"Feature"
//   }

export const formatLocationValue = ({ city, state, country, longitude, latitude }) => ({
  geometry: { type: 'Point', coordinates: [parseFloat(longitude), parseFloat(latitude)] },
  properties: { city, state, country, tag: 'hometown' },
  type: 'Feature',
});

export const searchCityNames = async (text: string) => {
  const res: any = await clientSearch(`city?charlist=${text}`).json();

  return res.result.map((item) => {
    const { id, city, state, country, longitude, latitude } = item;

    return {
      id,
      value: formatLocationValue({ city, state, country, longitude, latitude }),
      displayText: `${city} , ${state ? `${state} , ${country}` : country} `, // id state is available , then show state + country , else show only country
    };
  });
};
