// import { useRouter } from "next/router";
import { FC, ReactNode, useEffect } from 'react';

import { useRouter } from 'next/router';

import Navbar from '@components/Navbar';
import { useGetUser } from '@hooks/queries';

interface Props {
  children: ReactNode;
  showNav?: boolean;
}

/**
 * wraps all the protected pages eg : home page, profile , edit etc
 */
const MainLayout: FC<Props> = ({ children, showNav = true }) => {
  // const router = useRouter();

  // useGetActivePlans({ refetchOnMount: false });
  const router = useRouter();
  const { data: user, isFetching } = useGetUser({
    enabled: false,
  });

  useEffect(() => {
    if (!user && !isFetching) {
      router.push('/onboarding');
    } else if (
      user?.verificationStatus === 'unverified_by_admin' &&
      router.pathname !== '/edit-profile'
    ) {
      router.push('/verify-details');
    }
  }, [user]);
  // https://stackoverflow.com/questions/69203538/useeffect-dependencies-when-using-nextjs-router

  return (
    <div className="relative flex flex-col flex-1 overflow-hidden bg-white !h-[100vh]" id="layout">
      {/* height-stretch : is ok for android */}

      {showNav && <Navbar />}
      {children}
    </div>
  );
};

export default MainLayout;
