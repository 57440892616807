import { memo, useState } from 'react';

import AccordionWithSelect, {
  AccordionWithSelectProps,
} from '@components/Accordion/AccordionWithSelect';
import { HEIGHT_LIST } from '@configs/onboarding.personalDetails.config';
import { Item } from '@libs/types';
import { inchesIntoCms, inchesToFtAndInch } from '@utils/formatter';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: number;
  updateGlobalStore: (key, value) => void;
}

const Height = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(() => inchesToFtAndInch({ inches: savedValue }));
  const [displayValue, setDisplayValue] = useState(() =>
    inchesToFtAndInch({ inches: savedValue, withoutFtAndIn: false })
  );

  const onItemSelect = (item: Item) => {
    setValue(inchesToFtAndInch({ inches: item.value }));
    setDisplayValue(inchesToFtAndInch({ inches: item.value, withoutFtAndIn: false }));
    updateGlobalStore(accordionKey, item.value);
  };

  const formattedHeightList: Item[] = HEIGHT_LIST.map(({ value: heightValue, id }) => ({
    value: heightValue,
    displayText: `${inchesToFtAndInch({
      inches: parseInt(heightValue),
      withoutFtAndIn: false,
    })} (${inchesIntoCms(parseInt(heightValue))})`,
    id,
  }));
  const props: AccordionWithSelectProps = {
    list: formattedHeightList,
    title: 'Height',
    placeholder: 'Eg: 5 ft 4 in',
    selectedItem: value,
    value: value === '' ? undefined : value,
    displayValue: value === '' ? undefined : displayValue,
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithSelect {...props} />;
};

export default memo(Height);
