import { Dispatch, FC, InputHTMLAttributes, SetStateAction } from 'react';

import classNames from 'classnames';

import { Clear, Error } from '@components/index';

import SearchIcon from './icons/SearchIcon';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  errorMessage?: string;
  type?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onlyNumber?: boolean;
  searchField?: boolean;

  autoFocus?: boolean;
  onFocus?: () => void;

  rounded?: boolean;
}

const re = /^[0-9\b]+$/;

const Input: FC<InputProps> = ({
  placeholder,
  errorMessage,
  type = 'text',
  value,
  setValue,
  onlyNumber = false,
  searchField = false,
  onFocus,
  rounded = false,
  ...rest
}) => {
  const handleChange = (e) => {
    if (onlyNumber) {
      if (e.target.value === '' || re.test(e.target.value)) setValue(e.target.value);
    } else setValue(e.target.value);
  };

  return (
    <div>
      <div
        className={classNames('flex items-center space-x-2 bg-white ', {
          'rounded-full shadow py-3 px-4': rounded,
          'border-b-2 p-1 ': !rounded,
        })}
      >
        {searchField && <SearchIcon />}
        <input
          type={type}
          placeholder={placeholder}
          className="w-[95%] outline-none text-h6 "
          value={value}
          onChange={handleChange}
          required
          onFocus={onFocus}
          // autoFocus={false}
          {...rest}
        />
        {rounded && <Clear onClickHandler={() => setValue('')} visible={value !== ''} />}
      </div>
      {errorMessage && <Error message={errorMessage} />}
    </div>
  );
};

export default Input;
