import classNames from 'classnames';

interface ChipProps {
  testID?: string;
  /** How many chips should render in a row */
  columnSize?: 1 | 2 | 3;

  /** text to render inside the chip , */
  text: string;

  /** if the chip is active or not  */
  isSelected: boolean;

  /** Function to be called when the chip is clicked */
  onClickHandler: () => void;

  isSmall?: boolean;

  disabled?: boolean;
}

const Chip = ({ text, isSelected, onClickHandler }: ChipProps) => {
  return (
    <button onClick={onClickHandler}>
      <div
        className={classNames('border border-[#F5F5F5] p-3 rounded-full shadow-sm', {
          'bg-primaryPink': isSelected,
          'bg-white': !isSelected,
        })}
      >
        <span
          className={classNames('text-base', {
            'text-white': isSelected,
          })}
        >
          {text}
        </span>
      </div>
    </button>
  );
};

export default Chip;
