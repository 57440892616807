import classNames from 'classnames';
import { motion } from 'framer-motion';

interface Props {
  isDisabled?: boolean;
  onClickHandler: () => void;
  type: 'next';
  animate?: boolean;
  delayOnAnimation?: number;
  tailwindClasses?: string;
  id?: string;
}

const Fab = ({
  onClickHandler,
  animate,
  type = 'next',
  isDisabled,
  delayOnAnimation,
  tailwindClasses,
  id,
}: Props) => {
  return (
    <motion.button
      id={id}
      initial={{ scale: animate ? 0 : 1 }}
      animate={
        !animate
          ? false
          : {
              scale: 1,
              transition: {
                duration: 0.3,
                delay: delayOnAnimation || 0,
              },
            }
      }
      onClick={() => {
        onClickHandler();
      }}
      className={classNames(
        'fixed sm:absolute shadow-lg z-90 bg-opacity-100 bottom-6 right-6 bg-primaryPink w-14 h-14 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:shadow-4xl duration-300 ease-in-out hover:scale-110 transform transition',
        {
          'bg-disabled cursor-not-allowed ': isDisabled,
          [tailwindClasses]: tailwindClasses,
        }
      )}
    >
      {type === 'next' && (
        <span id={id}>
          <RightArrow id={id} />
        </span>
      )}
    </motion.button>
  );
};

export default Fab;

const RightArrow = ({ id }: { id: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={3}
    id={id}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
  </svg>
);
