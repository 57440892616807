/* eslint-disable no-shadow */
interface Geometry {
  coordinates: string[];
}

export interface Properties {
  city: string;
  state: string;
  country: string;
  tag: string;
}
interface Feature {
  type: string;
  geometry: Geometry;
  properties: Properties;
}
export interface Locations {
  type: string;
  features: Feature[];
}

export interface RecommendedUser {
  id: string;
  first_name: string;
  last_name?: string;
  locations: Locations;
  date_of_birth: string;
  designation: string;
  settle_down: string;
  currency?: string;
  company_name: string;
  religion: string;
  height: number;
  mother_tongue: string;
  highest_degree: string;
  college_name: string;
  income_from: number;
  income_to: number;
  food_preference: string;
  smoke_preference?: string;
  drink_preference?: string;
  marital_status?: string;
  images: string[];
  is_paid_user?: boolean;
  about?: string;
  caste: string;
  selfie_verification_status: string;
  blurred_images?: string[];
  govt_verification_status?: boolean;
  sparkle_message?: string;
  seen_at?: string;
  state?: string;
  rank?: string;
  intro_message?: string;
  active_at?: string;
}
export interface Recommendation {
  id: string;
  recommended_user: RecommendedUser;
  number_of_compatibility_bars?: unknown;
}

export type TokenType = {
  access: string;
  refresh: string;
};

export interface Main {
  title: string;
  subTitle: string;
  heroImageUrl: string;
  buttonText: string;
}

export interface Stat {
  number: string;
  text: string;
}

interface Footer {
  text: string;
}

export interface CarouselItem {
  imageUrl: string;
  title: string;
  subTitle: string;
}
export interface LandingPageData {
  main: Main;
  stats: Stat[];
  carouselItems: CarouselItem[];
  footer: Footer;
}
export interface Item {
  id: number | string;
  value: string;
  displayText: string;
  testID?: string;
}
export type Gender = 'male' | 'female';

export interface User {
  religion: string;
  motherTongue: string;
  height: number;
  incomeFrom: number;
  incomeTo: number;
  about: string;
  currency: string;
  status: string;
  id: string;
  highestDegree: string;
  collegeName: string;
  companyName: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  email: string;
  caste: string;
  designation: string;
  foodPreference: string;
  smokePreference: string;
  drinkPreference: string;
  maritalStatus: string;
  dateOfBirth: string;
  locations: Locations;
  images: string[];
  settleDown: string;
  mobile: string;
  isdCode: string;
  predictedFields: string[];
  verificationStatus: string;
  onBoardingMedium: string;
  migrationStatus: string;
  isPaidUser: boolean;
  selfieVerificationStatus: string;
  showAppReview: boolean;
  compatibilityQuestionInfo: string;
  isCompatibilityQuestionsAvailable: boolean;
  isMobileVerified: string;
  connectionLeft: number;
  isVerified: boolean;
  isForcedVerified: boolean;
  showIntroNudge: string;
  backgroundVerificationSubscriptionStatus?: any;
  gvDaysLeft?: number;
}

export type NotFilled = {
  settle_down: string;
  company_name: string;
  college_name: string;
  income_from: number;
  income_to: number;
  food_preference: string;
  locations: any;
  height: string;
};

// Edit Profile Props
export interface PreferenceTypes {
  fieldKey: string;
  list?: Item[];
  title: string;
  placeholder?: string;
  open?: boolean;
  value: string;
  onItemSelect?: (item: Item) => void;
  selectedItem?: string | Array<Item> | string[] | number[];
  onClick: (accordionKey: string) => void;
  titleStyles?: string;
  subtitleStyles?: string;
  containerStyles?: string;
  showCompletedIcon?: boolean;
}

export interface PreferenceBsTypes {
  key?: string;
  field?: string;
  title: string;
  subtitle?: string;
  onClick?: () => void;
  value?: string;
  list?: Array<Item>;
  selectedItems?: Item[] | string[];
  fieldType?: string;
  min?: number;
  max?: number;
  defaultValue?: Array<number>;
  onChangePref?: (item: any) => void;
  isDataChanged?: boolean;
}

export interface PPdata {
  id: number;
  modified_at: string;
  created_at: string;
  cities: string[];
  marital_status: string[];
  languages: string[];
  religions: string[];
  food_preference: any[];
  age_from: number;
  age_to: number;
  height_from: number;
  height_to: number;
  income_from: number;
  income_to: number;
  must_have: string[];
  nice_to_have: string[];
  predicted_fields: string[];
}

export interface PPDataUpperCase {
  id: number;
  modifiedAt: string;
  createdAt: string;
  cities: string[];
  maritalStatus: string[];
  languages: string[];
  religions: string[];
  foodPreference: any[];
  ageFrom: number;
  ageTo: number;
  heightFrom: number;
  heightTo: number;
  incomeFrom: number;
  incomeTo: number;
  mustHave: string[];
  niceToHave: string[];
  predictedFields: string[];
}

export type FOOD_PREFERENCES_TYPE = string[];

// Country code name
export interface SelectedCountry {
  id: string;
  name: string;
  code: string;
  numberOfDigits: string;
}

// Item Types for Reorder pictures
export const ItemTypes = {
  CARD: 'card',
};
export interface SentRequestsQueuedResponse {
  count?: number;
  next: string;
  results: any;
}

// sent request types

// eslint-disable-next-line no-shadow
export enum Country {
  India = 'India',
}

export interface Receiver {
  id?: string;
  first_name?: string;
  last_name?: string;
  locations?: Locations;
  date_of_birth?: string;
  designation?: string;
  settle_down?: string;
  currency?: string;
  company_name?: string;
  religion?: string;
  height?: number;
  mother_tongue?: string;
  highest_degree?: string;
  college_name?: string;
  income_from?: number;
  income_to?: number;
  food_preference?: string;
  smoke_preference?: string;
  drink_preference?: string;
  marital_status?: string;
  images?: string[];
  is_paid_user?: boolean;
  about?: string;
  caste?: string;
  selfie_verification_status?: string;
  astro_available?: boolean;
  govt_verification_status?: boolean;
  current_city?: string;
  current_country?: Country;
  active_at?: string;
}

export interface SentRequestResult {
  id?: number;
  receiver?: Receiver;
  seen_at?: null;
  rank?: number;
  count?: number;
  created_at?: Date;
  intro_message?: string;
  sparkle_message?: string;
  state?: string;
  sender?: string;
}
export interface SentRequestDataResponse {
  next?: string;
  results?: SentRequestResult[];
}

// eslint-disable-next-line no-shadow
export enum GeometryType {
  Point = 'Point',
}

// eslint-disable-next-line no-shadow
export enum Tag {
  Hometown = 'hometown',
  Residential = 'residential',
}

// eslint-disable-next-line no-shadow
export enum FeatureType {
  Feature = 'Feature',
}

export interface Geometries {
  type?: GeometryType;
  coordinates?: number[];
}

export interface Features {
  type?: FeatureType;
  geometry?: Geometries;
  properties?: Properties;
}

// User Active Plan

export interface PremiumPlan {
  name: string;
  start_date: string;
  end_date: string;
  amount: number;
  is_consumable: boolean;
  consumable_quantities: number;
  feature_name: any;
}

export interface SparklePlan {
  name: string;
  start_date: any;
  end_date: any;
  amount: number;
  is_consumable: boolean;
  consumable_quantities: number;
  feature_name: string;
}

export interface BackgroundVerificationPlan {
  name: string;
  start_date: any;
  end_date: any;
  amount: number;
  is_consumable: boolean;
  consumable_quantities: number;
  feature_name: any;
}

export interface UserActivePlanData {
  premium_plan: PremiumPlan;
  sparkle_plan: SparklePlan[];
  human_matchmaker_plan: any;
  background_verification_plan: BackgroundVerificationPlan;
  kundali_plan: any[];
}

export interface ConnectDeclineData {
  type: 'decline' | 'connect' | 'sendSparkles' | 'intro_message';
  userId?: string;
  recommendationId?: string;
  message?: string;
  is_mobile_verified?: string;
}
