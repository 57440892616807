import { ReactNode, useEffect } from 'react';

import classNames from 'classnames';
import { HTTPError } from 'ky';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { isSafari } from 'react-device-detect';

import logoSmall from '@assets/images/logo_small.png';
import { useFetchGeneralConfig } from '@configs/generalConfig';
import { USER_ONBOARDING_STATUS } from '@configs/onBoarding.config';
import { useGetConfig, useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import { User } from '@libs/types';
import { useGlobalStore } from '@zustand/store';

/**
 * Wraps the whole app -> MainLayout , OnBoardingLayout
 */

interface Props {
  children: ReactNode;
}

const LayoutWrapper = ({ children }: Props) => {
  const { pathname } = useRouter();
  const router = useRouter();
  const signOut = useGlobalStore((state) => state.signOut);

  const analyticsManager = useAnalyticsManager();

  const { refetch: refetchGeneralCOnfig } = useFetchGeneralConfig();
  useGetConfig();
  const { data: user, isLoading } = useGetUser({
    onSettled: refetchGeneralCOnfig,

    onError: async (error: HTTPError) => {
      if (error?.response?.status === 401) {
        router.push('/onboarding');
        signOut();
      }
    },
  });

  // if the user is logged it , but the onboarding is not completed , redirect to onboarding
  useEffect(() => {
    if (user?.status === USER_ONBOARDING_STATUS.ON_BOARDING_STARTED) router.push('/onboarding');
  }, [user]);

  // set the identify , when the component did mount
  useEffect(() => {
    const setUserProfileInAnalyticsManager = (userData: User) => {
      const {
        verificationStatus = '',
        locations: { features } = { features: [] },
        mobile = '',
        isdCode = '91',
        id = '',
      } = userData;

      let city: string;
      let country: string;
      // eslint-disable-next-line no-restricted-syntax
      for (const feature of features) {
        if (feature.properties?.tag === 'residential') {
          city = feature.properties?.city;
          country = feature.properties?.country;
          break;
        }
      }

      const userProfile = {
        Identity: id,
        verificationStatus,
        Phone: `+${isdCode}${mobile}`,
        City: city,
        Country: country,
        onboarding_platform: 'web',
      };
      analyticsManager.setUserProfile(userProfile);
    };
    if (user) setUserProfileInAnalyticsManager(user);
  }, [user]);

  return (
    <div
      className={classNames('flex flex-col flex-1 center', {
        'h-[100vh] sm:h-auto': isSafari,
      })}
    >
      <div
        className={classNames(
          'w-full overflow-hidden  flex-1  sm:flex-initial bg-white  flex flex-col sm:w-[375px] sm:h-[667px]  ',
          {
            'sm:rounded-2xl ': pathname === '/onboarding',
          }
        )}
      >
        {isLoading ? (
          // WORST LINE OF CODE IN THE CODEBASE :  have to use h tag else not working as of now
          <h5 className="grid h-full place-items-center">
            <Image src={logoSmall} alt="logo" width={150} height={150} priority />
          </h5>
        ) : (
          <> {children} </>
        )}
      </div>
    </div>
  );
};

export default LayoutWrapper;
