import create, { GetState, SetState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { TokenType, User } from '@libs/types';

interface GlobalState {
  token: TokenType;
  status: 'idle' | 'signIn' | 'signOut';
  isNewUser: boolean; // will be used to show the animation,
  sparkleNudgeCount: number;
  queuedVisited?: boolean;
  activeTab?: string;
  queuedConnection?: number;
  connectionLeftCount?: number;
  showReceivedBottomsheet: boolean;
  showReceivedReqBanner: boolean;
  acceptConnectionModalCount: number;
  declineConnectionModalCount: number;
  introMessageInfoCount: number;
  connectInfoCount?: number;
  declineInfoCount?: number;
  firstConnectGvModalShown?: boolean;
  isPrivacyClickedOnce?: boolean;
  lastGvModalShown?:
    | 'first-connect'
    | 'daily-free-connect-exhaust'
    | 'daily-all-connect-exhaust'
    | 'verify-force';

  gvModalsShown?: Array<
    'first-connect' | 'daily-free-connect-exhaust' | 'daily-all-connect-exhaust' | 'verify-force'
  >;

  openedGVModal?:
    | 'first-connect'
    | 'daily-free-connect-exhaust'
    | 'daily-all-connect-exhaust'
    | 'verify-force'
    | null;

  recommendationCount?: number;
  isOpenSelfieVerification?: boolean;
  isSelfieReverify?: boolean;
  isActiveSelfieBadge: boolean;
  signIn: (token: TokenType, user?: User) => void;
  signOut: () => void;
  toggleNewUser: (isNewUser: boolean) => void; // will be called at the last step of onboarding
  dataFromUrl: {
    [key: string]: string;
  };
  isOpenGovtVerification?: boolean;
  setDataFromUrl: (obj: { [key: string]: string }) => void;
  isZeroRecommendationLeft: boolean;
  setZeroRecommendationLeft: (value: boolean) => void;
  setSparkleNudgeCount: (value: number) => void;
  setQueuedVisited: (value: boolean) => void;
  setQueuedConnection: (value: number) => void;
  setConnectionLeft?: (value: number) => void;
  setShowReceivedBottomsheet: (value: boolean) => void;
  setActiveTab: (value: string) => void;
  setShowReceivedReqBanner: (value: boolean) => void;
  setAcceptConnectionModalCount: (value: number) => void;
  setDeclineConnectionModalCount: (value: number) => void;
  setIntroMessageInfoCount: (value: number) => void;
  setConnectInfoCount: (value: number) => void;
  setDeclineInfoCount: (value: number) => void;
  setFirstConnectGvModalShown: (value: boolean) => void;
  setLastGvModalShown: (
    value: 'daily-free-connect-exhaust' | 'daily-all-connect-exhaust' | 'verify-force' | null
  ) => void;

  // try
  setGVModalsShown: (
    value: Array<
      'first-connect' | 'daily-free-connect-exhaust' | 'daily-all-connect-exhaust' | 'verify-force'
    >
  ) => void;

  setGVModalOpened: (
    value:
      | 'first-connect'
      | 'daily-free-connect-exhaust'
      | 'daily-all-connect-exhaust'
      | 'verify-force'
      | null
  ) => void;
  setRecommendationCount: (value: number) => void;
  setIsOpenSelfieVerification: (value: boolean) => void;
  setIsOpenGovtVerification: (value: boolean) => void;
  setIsSelfieReverify: (value: boolean) => void;
  setIsActiveSelfieBadge: (value: boolean) => void;
  setPrivacyPolicyClickedOnce?: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateStore = (set: SetState<GlobalState>, get: GetState<GlobalState>): GlobalState => ({
  token: null,
  sparkleNudgeCount: 0,
  showReceivedBottomsheet: true,
  status: 'idle',
  isNewUser: false,
  dataFromUrl: {},
  queuedVisited: false,
  showReceivedReqBanner: true,
  queuedConnection: 0,
  acceptConnectionModalCount: 0,
  declineConnectionModalCount: 0,
  introMessageInfoCount: 0,
  connectInfoCount: 0,
  declineInfoCount: 0,
  firstConnectGvModalShown: false,
  lastGvModalShown: null,
  gvModalsShown: [],
  recommendationCount: 1,
  isOpenSelfieVerification: false,
  isOpenGovtVerification: false,
  isSelfieReverify: false,
  isActiveSelfieBadge: false,
  isPrivacyClickedOnce: false,
  setDataFromUrl: (obj: { [key: string]: string }) => set({ dataFromUrl: obj }),
  signIn: (token: TokenType) => {
    set({ token, status: 'signIn' });
  },
  signOut: () => {
    set({
      token: null,
      dataFromUrl: {},
      status: 'signOut',
      queuedVisited: false,
      queuedConnection: 0,
      connectionLeftCount: 0,
      sparkleNudgeCount: 0,
      connectInfoCount: 0,
      declineInfoCount: 0,
      introMessageInfoCount: 0,
      acceptConnectionModalCount: 0,
      declineConnectionModalCount: 0,
      firstConnectGvModalShown: false,
      lastGvModalShown: null,
      gvModalsShown: [],
      recommendationCount: 1,
      isOpenSelfieVerification: false,
      isOpenGovtVerification: false,
      isSelfieReverify: false,
      isActiveSelfieBadge: false,
      isPrivacyClickedOnce: false,
    });
    // redirect to the home page
  },

  setConnectionLeft: (value: number) => set({ connectionLeftCount: value }),
  setIntroMessageInfoCount: (value: number) => set({ introMessageInfoCount: value }),
  setQueuedVisited: (value: boolean) => set({ queuedVisited: value }),
  setQueuedConnection: (date: number) => set({ queuedConnection: date }),
  setAcceptConnectionModalCount: (data: number) => set({ acceptConnectionModalCount: data }),
  setDeclineConnectionModalCount: (data: number) => set({ declineConnectionModalCount: data }),
  setSparkleNudgeCount: (sparkleCount: number) => set({ sparkleNudgeCount: sparkleCount }),
  setConnectInfoCount: (count: number) => set({ connectInfoCount: count }),
  setDeclineInfoCount: (count: number) => set({ declineInfoCount: count }),
  setRecommendationCount: (count: number) => set({ recommendationCount: count }),
  setIsOpenSelfieVerification: (value: boolean) => set({ isOpenSelfieVerification: value }),
  setIsOpenGovtVerification: (value: boolean) => set({ isOpenGovtVerification: value }),
  setIsSelfieReverify: (value: boolean) => set({ isSelfieReverify: value }),
  setPrivacyPolicyClickedOnce: (value: boolean) => set({ isPrivacyClickedOnce: value }),
  toggleNewUser: (isNewUser: boolean) => set({ isNewUser }),
  isZeroRecommendationLeft: false,
  setZeroRecommendationLeft: (value) => set({ isZeroRecommendationLeft: value }),
  setShowReceivedBottomsheet: (value: boolean) => set({ showReceivedBottomsheet: value }),
  setActiveTab: (value) => set({ activeTab: value }),
  setShowReceivedReqBanner: (value) => set({ showReceivedReqBanner: value }),
  setFirstConnectGvModalShown: (value: boolean) => set({ firstConnectGvModalShown: value }),
  setLastGvModalShown: (
    value:
      | 'first-connect'
      | 'daily-free-connect-exhaust'
      | 'daily-all-connect-exhaust'
      | 'verify-force'
      | null
  ) => set({ lastGvModalShown: value }),

  setGVModalsShown: (
    gvModal: Array<
      'first-connect' | 'daily-free-connect-exhaust' | 'daily-all-connect-exhaust' | 'verify-force'
    >
  ) => set({ gvModalsShown: gvModal }),

  setGVModalOpened: (
    value:
      | 'first-connect'
      | 'daily-free-connect-exhaust'
      | 'daily-all-connect-exhaust'
      | 'verify-force'
      | null
  ) => set({ openedGVModal: value }),
  setIsActiveSelfieBadge: (value) => set({ isActiveSelfieBadge: value }),
});

export const useGlobalStore = create(
  persist(process.env.NODE_ENV !== 'production' ? devtools(generateStore) : generateStore, {
    name: 'state__bh',
  })
);
