import {
  Dispatch,
  FC,
  Fragment,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useReducer,
} from 'react';

import classNames from 'classnames';

import { Clear } from '@components/index';
import { formatNumber } from '@utils/formatter';
import { MonthsData, getMonthIndex, range } from '@utils/general';

type InitialState = {
  currentState: string;
  day: number;
  month: string;
  year: number;
};
interface Props {
  isSaveButtonEnable: (date: string) => void;
  setError: Dispatch<SetStateAction<boolean>>;
  formatDob: (obj) => void;
  initialState?: InitialState;
}

type ACTION_TYPE =
  | { type: 'SET_CURRENT_STATE'; payload: string }
  | { type: 'SET_DAY'; payload: number }
  | { type: 'SET_MONTH'; payload: string }
  | { type: 'SET_YEAR'; payload: number }
  | { type: 'RESET' };

const reducer = (state: InitialState, action: ACTION_TYPE) => {
  switch (action.type) {
    case 'SET_CURRENT_STATE':
      return { ...state, currentState: action.payload };
    case 'SET_DAY':
      return { ...state, day: action.payload, currentState: 'month' };
    case 'SET_MONTH':
      return { ...state, month: action.payload, currentState: 'year' };
    case 'SET_YEAR':
      return { ...state, year: action.payload };
    case 'RESET':
      return {
        currentState: 'day',
        day: null,
        month: null,
        year: null,
      };
  }
};

const Calender = ({ isSaveButtonEnable, formatDob, setError, initialState }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // check if all the fields are field then only make the button active
  useEffect(() => {
    isSaveButtonEnable(
      `${state.year}-${getMonthIndex(state.month)}-${
        state.day?.toString().length === 1 ? `0${state.day}` : state.day
      }`
    );
    formatDob(state);
  }, [state.day, state.month, state.year]);

  const handleSelectDay = (value: number) => dispatch({ type: 'SET_DAY', payload: value });

  const handleSelectMonth = (value: string) => dispatch({ type: 'SET_MONTH', payload: value });

  const handleSelectYear = (value: number) => dispatch({ type: 'SET_YEAR', payload: value });

  const resetCalender = () => dispatch({ type: 'RESET' });

  const CALENDAR_BUTTONS = [
    { id: 1, type: 'day', value: state.day, short: 'dd' },
    { id: 2, type: 'month', value: state.month, short: 'mm' },
    { id: 3, type: 'year', value: state.year, short: 'yyyy' },
  ];

  const CALENDAR_BODY_MAPPING = {
    day: <SelectDay onChange={handleSelectDay} value={state.day} />,
    month: (
      <SelectMonth onChange={handleSelectMonth} value={state.month} selectedDate={state.day} />
    ),
    year: <SelectYear onChange={handleSelectYear} value={state.year} />,
  };

  return (
    <div className="px-5 py-2 bg-white border rounded-2xl" onClick={() => setError(false)}>
      <div className="flex items-center justify-between py-2 mb-2">
        <div className="flex">
          {CALENDAR_BUTTONS.map(({ type, short, id, value }, index) => (
            <Fragment key={index}>
              <button
                className={classNames('mr-2 focus:outline-none', {
                  '!text-primaryPink': state.currentState === type,
                  'text-black': value,
                  'text-silver': !value && state.currentState !== type,
                })}
                onClick={() => dispatch({ type: 'SET_CURRENT_STATE', payload: type })}
                key={id}
              >
                {formatNumber(value) || short}
                {index != 2 && <span className="text-silver">&nbsp; {` - `}</span>}
              </button>
            </Fragment>
          ))}
        </div>
        <Clear onClickHandler={resetCalender} visible={true} />
      </div>

      <div className="flex flex-col space-y-2 h-[200px]">
        <h5 className="pl-2">Select a {state.currentState}</h5>
        {CALENDAR_BODY_MAPPING[state.currentState]}
      </div>
    </div>
  );
};

export default Calender;

// nested button inside calender body

interface SelectDateButtonProps<T> {
  onClickHandler: (newValue: T) => void;
  selectedValue: T;
  currentValue: T;
  disabled?: boolean;
}
const SelectDateButton = <T,>({
  onClickHandler,
  selectedValue,
  currentValue,
  disabled,
}: PropsWithChildren<SelectDateButtonProps<T>>) => {
  return (
    <button
      className={classNames(
        'col-span-1 p-[6px] text-sm bg-opacity-80 hover:text-white hover:bg-pink-500 hover:rounded-full focus:outline-none',
        {
          'text-white bg-pink-500 rounded-full': selectedValue === currentValue,
          'hover:bg-[gray] text-gray-400': disabled === true,
        }
      )}
      onClick={() => onClickHandler(currentValue)}
      disabled={disabled}
    >
      {/* @ts-ignore : - */}
      {currentValue?.toString().length === 1 ? `0${currentValue}` : currentValue}
    </button>
  );
};

interface SelectYearProps {
  onChange: (year: number) => void;
  value: number;
}
const SelectYear: FC<SelectYearProps> = ({ onChange, value }) => {
  return (
    <div className="grid grid-cols-4 h-[160px] overflow-y-scroll">
      {range(1973, 2004).map((_year) => (
        <SelectDateButton<number>
          key={_year}
          onClickHandler={onChange}
          selectedValue={value}
          currentValue={_year}
        />
      ))}
    </div>
  );
};

interface SelectMonthProps {
  onChange: (month: string) => void;
  value: string;
  selectedDate?: number;
}
const SelectMonth: FC<SelectMonthProps> = ({ onChange, value, selectedDate }) => {
  return (
    <div className="grid grid-cols-4 ">
      {Object.keys(MonthsData).map((_month) => (
        <SelectDateButton<string>
          key={_month}
          disabled={MonthsData[_month].days < Number(selectedDate)}
          onClickHandler={onChange}
          selectedValue={value}
          currentValue={_month}
        />
      ))}
    </div>
  );
};

interface SelectDayProps {
  onChange: (day: number) => void;
  value: number;
}
const SelectDay: FC<SelectDayProps> = ({ onChange, value }) => {
  return (
    <div className="grid grid-cols-7 ">
      {range(1, 31).map((_day) => (
        <SelectDateButton<number>
          key={_day}
          onClickHandler={onChange}
          selectedValue={value}
          currentValue={_day}
        />
      ))}
    </div>
  );
};
