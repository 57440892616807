import { useQuery } from 'react-query';

import { ENDPOINTS } from '@configs/api.config';
import client from '@utils/client';

type Count = {
  count: number;
};

const getReceivedReqCount = (): Promise<Count> =>
  client.get(ENDPOINTS.GET_RECEIVED_REQUESTS_COUNT).json();
const useReceivedRequestCount = () =>
  useQuery<Count, Error, Count>([ENDPOINTS.GET_RECEIVED_REQUESTS_COUNT], getReceivedReqCount, {
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });
export default useReceivedRequestCount;
