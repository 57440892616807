import { memo, useState } from 'react';

import { searchCityNames } from 'api/search';
import { BottomSheet } from 'react-spring-bottom-sheet';

import AccordionWithList, { AccordionWithListProps } from '@components/Accordion/AccordionWithList';
import SearchList, { SearchListProps } from '@components/SearchList';
import CollegeIcon from '@components/icons/CollegeIcon';
import { LOCATION_LIST } from '@configs/onBoarding.config';
import { Item } from '@libs/types';

// TODO : need to refactor this component

interface Props {
  accordionKey: string;
  savedValue: any;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  updateGlobalStore: (key, value) => void;
}

const LocationSearch = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(savedValue);
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false);

  const { properties: { city = '', state = '', country = '' } = {} } = value || {};

  const displayValue = value
    ? `${city} , ${state ? `${state} , ${country}` : country} `
    : undefined;

  const onItemSelect = (item: Item) => {
    // @ts-ignore : -
    setValue(item.value);
    toggleBottomSheet(false);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithListProps = {
    // @ts-ignore : -
    list: LOCATION_LIST,
    title: 'Home Town',
    placeholder: 'Eg: Indore',
    showSeeMore: true,
    onSeeMorePress: () => toggleBottomSheet(true),
    selectedItem: value ? `${city} , ${state ? `${state} , ${country}` : country} ` : undefined,
    // @ts-ignore : -
    value: value,
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
    displayValue,
  };

  const KEY_TO_SEARCH_LIST_PROPS: SearchListProps = {
    onSelect: onItemSelect,
    onSearchCallback: searchCityNames,
    placeholder: 'Search your hometown',
    // @ts-ignore : -
    initialData: LOCATION_LIST,
    selectedData: value ? `${city} , ${state ? `${state} , ${country}` : country} ` : undefined,
    Icon: CollegeIcon,
    hideOptionToAdd: true,
  };

  return (
    <>
      <AccordionWithList {...props} />
      <BottomSheet open={isBottomSheetOpen} onDismiss={() => toggleBottomSheet(false)}>
        <SearchList {...KEY_TO_SEARCH_LIST_PROPS} />
      </BottomSheet>
    </>
  );
};

export default memo(LocationSearch);
