import { useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { Item } from '@libs/types';
import debounce from '@utils/debounce';
import { capitalizeFirstLetter } from '@utils/formatter';
import { uniqueIdGenerator } from '@utils/general';

import Input from './Input';

export interface SearchListProps {
  initialData: Item[];
  onSelect: (value: Item, isNewItem?: boolean) => void;
  onSearchCallback: (search: string) => Promise<any>;
  placeholder: string;
  selectedData: string;
  hideOptionToAdd?: boolean;
  Icon?: ({ color }: { color?: string }) => JSX.Element;
}

const SearchList = ({
  initialData,
  onSelect,
  onSearchCallback,
  placeholder,
  selectedData,
  hideOptionToAdd = false,
  Icon,
}: SearchListProps) => {
  const [filteredData, setFilteredData] = useState(initialData);
  const [searchInput, setSearchInput] = useState('');

  /* This is the to store the most current searchInput which will be used inside useMemo */
  const searchInputRef = useRef('');

  const debouncedSearch = useMemo(
    () =>
      debounce(async () => {
        if (searchInputRef.current !== '') {
          const searchResults = await onSearchCallback(searchInputRef.current);
          setFilteredData(searchResults);
        }
      }, 250),
    []
  );

  const handleSearch = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchInput(searchTerm);
    searchInputRef.current = trimmedSearchTerm;

    if (trimmedSearchTerm === '') {
      setFilteredData(initialData);
    } else {
      debouncedSearch();
    }
  };

  const handleSelectDataFromSearchList = (element: Item) => {
    onSelect(element);
    setSearchInput('');
    setFilteredData(initialData);
  };
  return (
    <div className="p-2">
      {/* SEARCH INPUT */}

      <Input
        placeholder={placeholder}
        searchField
        value={searchInput}
        setValue={handleSearch}
        rounded
      />

      {/* DATA_LIST  */}
      <div className="flex flex-col items-start px-3 py-1 mt-2 space-y-2 text-sm h-52">
        {filteredData?.slice(0, 6)?.map((item) => (
          <button
            onClick={() => {
              handleSelectDataFromSearchList(item);
            }}
            key={item.id}
            className={classNames('flex space-x-2 items-center justify-start', {
              'text-primaryPink': selectedData === item.value,
            })}
          >
            {Icon && <Icon color="#333" />}
            <span className="text-[#333] text-left p-1">{item.displayText}</span>
          </button>
        ))}

        {/* button to add searched value */}
        {!hideOptionToAdd && searchInput.length > 0 && (
          <button
            onClick={() => {
              const newItem = {
                id: uniqueIdGenerator(),
                value: searchInput,
                displayText: capitalizeFirstLetter(searchInput),
              };
              onSelect(newItem, true);
            }}
          >
            <span className="text-primaryPink"> Add {searchInput}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchList;
