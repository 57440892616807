import React, { ReactElement } from 'react';

const URL = 'https://wa.me';

interface IProps {
  number: string;
  message: string;
  children: ReactElement;
  onClickCallBack?: (event) => void;
}

const WhatsAppContact = ({ number, message, children, onClickCallBack }: IProps) => {
  number = number?.replace(/[^\w\s]/gi, '').replace(/ /g, ''); // remove spaces in mobile number

  let url = `${URL}/${number}`;

  if (message) {
    url += `?text=${encodeURI(message)}`;
  }

  return (
    <button
      onClick={(e) => {
        window.open(url);
        onClickCallBack?.(e);
      }}
    >
      {children}
    </button>
  );
};

export default WhatsAppContact;
