import { memo, useEffect, useState } from 'react';

import Accordion, { AccordionProps } from '@components/Accordion/Accordion';
import SaveButton from '@components/common/SaveButton';
import { Name as NameType } from '@components/onBoardingSteps/StepCombined';
import { useMutateUserData } from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import { splitName } from '@utils/formatter';
import { isValidName } from '@utils/validation';

import { Input } from '..';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
  onNameChange: (value: NameType) => void; // TODO : FIX THIS
}

const Name = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
  onNameChange,
}: Props) => {
  const [firstName, setFirstName] = useState(() => splitName(savedValue).firstName);
  const [lastName, setLastName] = useState(() => splitName(savedValue).lastName);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const { data: user } = useGetUser({
    enabled: false,
  });

  const { firstName: savedFirstName, lastName: savedLastName } = user || {};

  const { mutate: updateUserData } = useMutateUserData();

  const props: Omit<AccordionProps, 'children'> = {
    title: 'Name',
    placeholder: 'Eg: Sumit Dey',
    value: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    isOpen: isAccordionOpen,
    accordionKey,
    toggleAccordion: toggleAccordionByKey,
    isError: !isButtonEnabled,
  };

  // enable the button when firstName length is 2 and lastName length is 1
  useEffect(() => {
    if (firstName.length >= 2 && lastName.length >= 1) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [firstName, lastName]);

  const onSave = () => {
    updateGlobalStore(accordionKey, `${firstName} ${lastName}`);

    // FOR OPTIMIZATION : make the patch request to update the user data only if the user has changed the name
    if (savedFirstName !== firstName || savedLastName !== lastName) {
      updateUserData({ first_name: firstName, last_name: lastName });
    }
  };

  const handleFirstNameChange = (value) => {
    if (isValidName(value)) {
      setFirstName(value);
      onNameChange({
        firstName: value,
        lastName: lastName,
      });
    }
  };

  const handleLastNameChange = (value) => {
    if (isValidName(value)) {
      setLastName(value);
      onNameChange({
        firstName: firstName,
        lastName: value,
      });
    }
  };

  return (
    <Accordion {...props}>
      <div className="flex flex-col flex-1 space-y-3 w-[98%] justify-center mx-auto pt-2">
        <Input
          placeholder="First name"
          value={firstName}
          setValue={handleFirstNameChange}
          maxLength={16}
          autoFocus
        />
        <Input
          placeholder="Last name"
          value={lastName}
          setValue={handleLastNameChange}
          maxLength={10}
        />

        <SaveButton isButtonEnabled={isButtonEnabled} handleNext={onSave} />
      </div>
    </Accordion>
  );
};

export default memo(Name);
