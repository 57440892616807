import { useRef } from 'react';

import { motion } from 'framer-motion';
import Select, { BaseSelectRef, Option } from 'rc-select';

import { Item } from '@libs/types';

import Accordion, { AccordionProps } from './Accordion';

export type AccordionWithSelectProps = Omit<AccordionProps, 'children'> & {
  list: Array<Item>;
  onItemSelect: (item: Item) => void;
  selectedItem: string | Item | undefined | string[];
  // eslint-disable-next-line react/require-default-props
  displayValue?: string | undefined;
  placeholder: string;
};

const AccordionWithSelect = ({
  list,
  onItemSelect,
  value,
  displayValue,
  isOpen,
  placeholder,
  ...props
}: AccordionWithSelectProps) => {
  const onSelect = (_value, item: Item) => onItemSelect(item);

  const ref = useRef<BaseSelectRef>();

  return (
    <Accordion {...props} value={displayValue || value} isOpen={isOpen} placeholder={placeholder}>
      <Select
        ref={ref}
        className="rc-select "
        value={value === '' ? undefined : value}
        animation="slide-up"
        choiceTransitionName="rc-select-selection__choice-zoom"
        style={{ width: '90%', marginTop: '0.5rem' }}
        onSelect={onSelect}
        placeholder={placeholder}
        showSearch={false}
        showArrow
        inputIcon={ArrowDown}
        autoFocus
      >
        {list.map((item) => (
          <Option key={item.id} value={item.value}>
            {item.displayText}
          </Option>
        ))}
      </Select>
    </Accordion>
  );
};
export default AccordionWithSelect;

const ArrowDown = ({ isOpen }: { isOpen: boolean }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4"
    viewBox="0 0 20 20"
    fill="currentColor"
    initial={false}
    animate={{
      rotate: isOpen ? 180 : 0,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    }}
  >
    <path
      className=""
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </motion.svg>
);
