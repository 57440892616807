import ky from 'ky';

const client = ky.create({
  prefixUrl: process.env.NEXT_PUBLIC_BASE_API_URL,
  headers: {
    'app-Build-Number': '166',
    platform: 'android',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = JSON.parse(localStorage.getItem('state__bh'))?.state?.token?.access;
        if (accessToken) request.headers.set('Authorization', `Bearer ${accessToken}`);
      },
    ],
    afterResponse: [], //TODO: convert the response to json() for all the requests
  },
});

const clientSearch = ky.create({
  prefixUrl: 'https://search.betterhalf.ai/search/',
  // headers: {
  //   'app-build-number': '158',
  // },
  hooks: {
    beforeRequest: [],
    afterResponse: [], //TODO: convert the response to json() for all the requests
  },
});

const clientConfig = ky.create({
  prefixUrl: process.env.NEXT_PUBLIC_CONFIG_API_URL,
  // headers: {
  //   'platform': 'android',
  // },
});

const clientMatches = ky.create({
  headers: {
    'app-Build-Number': '166',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = JSON.parse(localStorage.getItem('state__bh'))?.state?.token?.access;
        if (accessToken) request.headers.set('Authorization', `Bearer ${accessToken}`);
      },
    ],
    afterResponse: [], //TODO: convert the response to json() for all the requests
  },
});

export { clientSearch, clientConfig, clientMatches };
export default client;
