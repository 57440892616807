import { createContext, useEffect, useState } from 'react';

import CleverTap from 'clevertap-web-sdk/clevertap';
import mixpanel, { OverridedMixpanel as Mixpanel } from 'mixpanel-browser';

// extend OverridedMixpanel interface to add the __loaded property
interface OverridedMixpanel extends Mixpanel {
  __loaded: boolean;
}

export interface AnalyticsManagerState {
  clevertap: CleverTap;
  mixpanel: OverridedMixpanel;
  branch: typeof import('../node_modules/@types/branch-sdk/index');
}

export const AnalyticsManagerContext = createContext<AnalyticsManagerState>(null);

const AnalyticsMangerProvider = ({ children }) => {
  const [clevertap, setClevertap] = useState(null);
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    (async () => {
      const clevertapSDK = (await import('clevertap-web-sdk')).default;
      const BranchSDK = (await import('branch-sdk')).default;

      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY, {
        debug: process.env.NODE_ENV === 'development',
        loaded: () => {
          // @ts-ignore : issue of mixpanel type definition
          mixpanel.__loaded = true;
        },
      });

      clevertapSDK?.init(process.env.NEXT_PUBLIC_CLEVERTAP_ID);
      setClevertap(clevertapSDK);
      clevertapSDK?.setLogLevel(1);

      BranchSDK.init(process.env.NEXT_PUBLIC_BRANCH_SDK_KEY, { tracking_disabled: false });
      setBranch(BranchSDK);

      BranchSDK?.data(function (err, data) {
        if (err) {
          console.warn(`Branch failed to resolve link: ${err}, ${data}`);
          return;
        }
      });
    })();
  }, []);

  return (
    // @ts-ignore : issue of mixpanel type definition
    <AnalyticsManagerContext.Provider value={{ clevertap, mixpanel, branch }}>
      {children}
    </AnalyticsManagerContext.Provider>
  );
};

export default AnalyticsMangerProvider;
