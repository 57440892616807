import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

import Fab from '@components/Fab';
import { Dob, Email } from '@components/Onboarding';
import Gender from '@components/Onboarding/Gender';
import Name from '@components/Onboarding/Name';
import { containerVariants, fadeInUp } from '@configs/motion.config';
import { useMutateUserData } from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import useAccordion from '@hooks/useAccordion';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import { Gender as TGender, User } from '@libs/types';
import { isEmpty, splitName } from '@utils/formatter';
import { checkIfEligibleForMarriage, getAge, isValidDate } from '@utils/general';
import { isValidEmail } from '@utils/validation';

const ACCORDION_KEYS = ['name', 'email', 'gender', 'date_of_birth'] as const;

export type Name = {
  firstName: string;
  lastName: string;
};

interface Props {
  setOnboardingStep: Dispatch<SetStateAction<number>>;
}

// returns true if the fullName is valid
const validateFullName = (fullName: Record<string, string>) =>
  !!fullName &&
  !isEmpty(fullName.firstName) &&
  !isEmpty(fullName.lastName) &&
  fullName?.firstName?.length > 1;

const StepCombined = ({ setOnboardingStep }: Props) => {
  const analyticsManager = useAnalyticsManager();

  const { data: user } = useGetUser({
    enabled: false,
  });

  const { firstName, lastName, email, gender, dateOfBirth } = user || {};

  // TODO : change the preFill data key according to the api(me)
  const [globalStore, setGlobalStore] = useState<
    Record<typeof ACCORDION_KEYS[number], string | undefined>
  >({
    name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    email: email || '',
    gender,
    date_of_birth: dateOfBirth,
  });

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { mutate: updateUserData } = useMutateUserData({
    onMutate: () => setOnboardingStep((step) => step + 1),
    onSuccess: (data: User) => {
      // analyticsManager.setUserProfile(userProfile);
      analyticsManager.sendEvent(
        'continue_on_profile_name_web',
        { cleverTap: 'yes', userId: user?.id },
        ['mixpanel', 'clevertap']
      );
      analyticsManager.sendEvent(
        'continue_with_birthdate_web',
        {
          cleverTap: 'yes',
          birthDaySelected: data.dateOfBirth,
          userId: user?.id,
          isMarketingEvent: true,
        },
        ['mixpanel', 'clevertap']
      );
      analyticsManager.sendEvent(
        'continue_with_emailid_web',
        { cleverTap: 'yes', userId: user?.id },
        ['mixpanel', 'clevertap']
      );
      analyticsManager.sendEvent(
        'continue_with_basic_details_web',
        {
          cleverTap: 'yes',
          userId: user?.id,
          firstName: data.firstName,
          birthDaySelected: data.dateOfBirth,
          email: data.email,
          genderSelected: data.gender,
          isMarketingEvent: true,
        },
        'all'
      );
    },
  });

  const {
    toggleAccordionByKey,
    isOpen: isAccordionOpen,
    openFirstAccordion,
    openNextAccordion,
  } = useAccordion(ACCORDION_KEYS, globalStore, {
    openInitialAccordionDelay: 1800,
  });

  useEffect(() => {
    openFirstAccordion();
  }, []);

  // the button will be active once all the fields are filled of prefilled data

  const validateForm = () => {
    const { date_of_birth, email: emailAddress, gender: userGender, name } = globalStore;

    // validate name
    if (!name || !validateFullName(splitName(name))) {
      setErrorMessage('Please enter full name');
      return false;
    }

    // validate email
    else if (!emailAddress || !isValidEmail(emailAddress)) {
      setErrorMessage('Please enter email');
      return false;
    }

    // validate gender
    else if (!userGender) {
      setErrorMessage('Please select your gender');
      return false;
    }

    // validate date of birth
    else if (!date_of_birth || !isValidDate(date_of_birth)) {
      setErrorMessage('Please enter date of birth');
      return false;
    } else if (!checkIfEligibleForMarriage(userGender as TGender, date_of_birth)) {
      setErrorMessage(
        userGender === 'male'
          ? 'Age should be between 21 to 50 years'
          : 'Age should be between 18 to 50 year'
      );
      return false;
    } else setErrorMessage('');
  };

  // form validation will be triggered on every change of the form
  useEffect(() => {
    validateForm();
  }, [globalStore]);

  // if any of the field gets updated, sync global object and open the next accordion , make sure to MAINTAIN THE ORDER
  const updateGlobalStore = useCallback(
    (key: typeof ACCORDION_KEYS[number], value: string) => {
      const updatedGlobalStore = { ...globalStore };
      updatedGlobalStore[key] = value;
      setGlobalStore(updatedGlobalStore);
      // @ts-ignore : -
      openNextAccordion(key, updatedGlobalStore);
    },
    [globalStore]
  );

  const onNameChange = (nameObj: Name) => {
    const updatedGlobalStore = { ...globalStore };
    updatedGlobalStore['name'] = `${nameObj.firstName} ${nameObj.lastName}`;
    setGlobalStore(updatedGlobalStore);
  };

  const onEmailChange = (userEmail: string) => {
    const updatedGlobalStore = { ...globalStore };
    updatedGlobalStore['email'] = userEmail;
    setGlobalStore(updatedGlobalStore);
  };

  const onDateOfBirthChange = (userDateOfBirth: string) => {
    const updatedGlobalStore = { ...globalStore };
    updatedGlobalStore['date_of_birth'] = userDateOfBirth;

    setGlobalStore(updatedGlobalStore);
  };

  const mutateUserProfileOnBackend = () => {
    if (!!errorMessage) {
      toast.error(errorMessage, {
        id: 'errorMessage',
      });
      return;
    }

    const { firstName: userFirstName, lastName: userLastName } = splitName(globalStore.name);
    const dataToBeSent = {
      ...globalStore,
      first_name: userFirstName,
      last_name: userLastName,
    };
    delete dataToBeSent.name;
    const userProfile = {
      email: globalStore?.email,
      gender: globalStore?.gender,
      dateOfBirth: globalStore?.date_of_birth,
      firstName: userFirstName,
      lastName: userLastName,
      name: `${userFirstName} ${userLastName}`,
      userAge: getAge(globalStore?.date_of_birth),
    };
    analyticsManager.setUserProfile(userProfile);
    updateUserData(dataToBeSent);
  };

  const commonProps = {
    toggleAccordionByKey,
    updateGlobalStore,
  };

  return (
    <div className="relative h-screen sm:h-full   !z-50 ">
      <motion.section
        className="h-full pt-6 pb-24 overflow-y-auto stepCombine"
        initial="initial"
        animate="animate"
        variants={containerVariants}
      >
        <motion.div className="flex flex-col mb-6 space-y-2">
          <motion.h2 variants={fadeInUp} className="text-xl w-[70%] ">
            Let&apos;s know each other better!
          </motion.h2>
          <motion.p variants={fadeInUp} className="text-sm text-gray-400">
            Tell us a few things about yourself.
          </motion.p>
        </motion.div>
        <Name
          {...commonProps}
          accordionKey="name"
          savedValue={globalStore.name}
          isAccordionOpen={isAccordionOpen('name')}
          onNameChange={onNameChange}
        />

        <Email
          {...commonProps}
          accordionKey="email"
          savedValue={globalStore.email}
          isAccordionOpen={isAccordionOpen('email')}
          onEmailChange={onEmailChange}
        />

        <Gender
          {...commonProps}
          accordionKey="gender"
          savedValue={globalStore.gender as TGender}
          isAccordionOpen={isAccordionOpen('gender')}
        />

        <Dob
          {...commonProps}
          accordionKey="date_of_birth"
          savedValue={globalStore.date_of_birth}
          isAccordionOpen={isAccordionOpen('date_of_birth')}
          onDateOfBirthChange={onDateOfBirthChange}
          gender={globalStore.gender}
        />
      </motion.section>
      <Fab
        isDisabled={!!errorMessage}
        animate
        delayOnAnimation={1} // button will pop up when the lottie animation is finished appx after 6 seconds
        onClickHandler={mutateUserProfileOnBackend}
        type="next"
        tailwindClasses="bottom-12 right-4"
        id="submit_basic_details"
      />
    </div>
  );
};

export default StepCombined;
