import React from 'react';

import ContactUS from 'features/plan/components/common/ContactUS';
import Image from 'next/image';

import timer from '@assets/images/timer.png';
import Button from '@components/Button';

interface IOtpLimitProps {
  onClose: () => void;
  contactUsDetails: {
    message: string;
    number: string;
  };
  ifOtpExhausted?: boolean;
}

export default function OtpLimitExceeded({
  onClose,
  contactUsDetails,
  ifOtpExhausted,
}: IOtpLimitProps) {
  return (
    <div>
      <div className="absolute top-0 left-0 bg-[white] flex justify-center items-center flex-col h-full w-full z-[999]">
        <Image width={72} height={72} className="rounded-full" src={timer} alt="timer image" />
        <h1 className="font-semiBold leading-[30px] text-[20px] text-[#333333] text-center mt-6">
          OTP limit expired!
        </h1>
        <div className="text-center text-[#333333] mt-2">
          {ifOtpExhausted ? (
            <p>Your daily OTP limit has been exhausted.</p>
          ) : (
            <p>
              {' '}
              Seems like your OTP limit got expired, <br /> come back after 1 min
            </p>
          )}
        </div>
        <Button
          label="Back to home"
          className='w-[50%] mt-10 font-["Century_Gothic"] text-[16px]'
          onClick={onClose}
        />
        <div className="absolute bottom-16">
          <ContactUS message={contactUsDetails?.message} number={contactUsDetails?.number} />
        </div>
      </div>
    </div>
  );
}
