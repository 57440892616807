import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useDrag, useDrop } from 'react-dnd';
import { useDropzone } from 'react-dropzone';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiLoaderCircle } from 'react-icons/bi';
import { IoMdAddCircle } from 'react-icons/io';

import { useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import { ItemTypes } from '@libs/types';

import { handleImageArgs, imageData } from './onBoardingSteps/StepPictureUpload';

interface Props {
  data: imageData;
  handleImage: (obj: handleImageArgs) => void;
  index: number;
  showDeleteButton: boolean;
  disableDelete: boolean;
  moveCard: (dragIndex, hoverIndex) => void;
  id: number;
}
const ImageInput: FC<Props> = ({
  data,
  handleImage,
  id,
  index,
  moveCard,
  disableDelete,
  showDeleteButton,
}) => {
  const { data: user } = useGetUser({
    enabled: false,
  });
  const userId = user?.id;
  const [placeholderImageUrl, setPlaceholderImageUrl] = useState<string | null>(
    data.localImageUrl || data.uploadedImageUrl || null
  );
  const analyticsManager = useAnalyticsManager();

  const onDrop = useCallback(
    // dropzone returns an array of files even if the multiple attribute is set to false
    (acceptedFiles: File[]) => {
      handleImage({
        action: 'add',
        localImageUrl: URL.createObjectURL(acceptedFiles[0]),
        imageFile: acceptedFiles[0],
        imageInputComponentIndex: index,
      });
      analyticsManager.sendEvent(
        'photo_upload_web',
        {
          userId: userId,
        },
        ['mixpanel']
      );
    },
    [handleImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'image/*': [] },
  });

  useEffect(() => {
    setPlaceholderImageUrl(data.uploadedImageUrl || data.localImageUrl);
  }, [data.localImageUrl, data.uploadedImageUrl]);

  const removeFile = () => {
    if (disableDelete) return;
    handleImage({ action: 'remove', imageInputComponentIndex: index });
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      if (data.localImageUrl === null && data.uploadedImageUrl === null) {
        return;
      }
      const dragIndex = item['index'];
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor?.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item['index'] = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      // if both localImageURl and uploadedImageURL are null return empty object as the card will not be draggable
      if (data.localImageUrl === null && data.uploadedImageUrl === null) {
        return {};
      }
      return { id, index, data };
    },
    collect: (monitor) => {
      if (data.localImageUrl === null && data.uploadedImageUrl === null) {
        return {};
      }
      return { isDragging: monitor.isDragging() };
    },
  });
  const opacity = isDragging ? 0 : 1; // if dragging set opacity to 0 for initial location of the item.
  if (data.localImageUrl || data.uploadedImageUrl) {
    drag(drop(ref));
  }

  return (
    <motion.div layout ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <div className="relative col-span-4 p-2 bg-white rounded-full shadow cursor-pointer">
        <div className={classNames('relative overflow-hidden rounded-full  ')}>
          <div
            {...getRootProps()}
            className="grid !p-7 border border-dashed rounded-full place-items-center text-primaryPink border-primaryPink"
          >
            {data.isUploading ? (
              <BiLoaderCircle size={26} className="z-50 animate-spin" />
            ) : (
              <IoMdAddCircle size={26} />
            )}
            <input {...getInputProps()} disabled={data.isUploading} />
          </div>
          <AnimatePresence exitBeforeEnter>
            {placeholderImageUrl && (
              <motion.div
                className="absolute inset-0 overflow-hidden rounded-full shadow"
                exit={{
                  opacity: 0,
                  scale: 0,
                  transition: { duration: 0.2 },
                }}
                animate={{ opacity: 1, scale: 1 }}
                initial={{ opacity: 0, scale: 0 }}
              >
                {/* eslint-disable-next-line */}
                <img
                  src={placeholderImageUrl}
                  alt="image name"
                  className={classNames('object-cover w-[100%] h-[100%]', {
                    blur: data.isUploading,
                  })}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {data.uploadedImageUrl && showDeleteButton && (
          <span
            className="absolute z-10 p-1 transform rotate-45 bg-white rounded-full shadow cursor-pointer bottom-2 right-2 "
            onClick={removeFile}
          >
            <AiOutlinePlus className=" text-primaryPink" />
          </span>
        )}
      </div>
    </motion.div>
  );
};

export default memo(ImageInput);
