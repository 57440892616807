import React, { useCallback, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import Image from 'next/image';
import { useQueryClient } from 'react-query';

import Circle from '@assets/circles.svg';
import Fab from '@components/Fab';
import { ENDPOINTS } from '@configs/api.config';
import { containerVariants, fadeInUp } from '@configs/motion.config';
import { useMutateUserData } from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import useAccordion from '@hooks/useAccordion';
import useAnalyticsManger from '@hooks/useAnalyticsManager';
import { User } from '@libs/types';
import aiFace from '@public/assets/images/prefilled/aiFace.png';

import Drinks from './Drinks';
import Smoke from './Smoke';

const ACCORDION_KEYS = ['drink_preference', 'smoke_preference'] as const; // Accordion keys

interface IProps {
  handleNext: () => void;
}

/**
 * @description Additional information page : will be displayed only when user status is onboarding_Completed and SmokePreference === null and drinkPreference === null
 */
export default function StepAdditionalInformation({ handleNext }: IProps) {
  const queryClient = useQueryClient();

  const { mutate: updateUserData } = useMutateUserData({
    onSuccess: (data: User) => {
      queryClient.setQueryData(ENDPOINTS.ME, (oldData: User) => ({
        ...oldData,
        smokePreference: data.smokePreference,
        drinkPreference: data.drinkPreference,
      }));
    },
  });

  const analyticsManager = useAnalyticsManger();

  const { data: user, status: queryStatus } = useGetUser({
    enabled: false,
  });

  const { smokePreference, drinkPreference } = user || {}; // get initial values (smoke, drink)

  // parent store to manage the updated smoke and drink preferences value.
  const [additionalInformation, setAdditionalInformation] = useState<
    Record<typeof ACCORDION_KEYS[number], string | undefined>
  >({
    drink_preference: drinkPreference,
    smoke_preference: smokePreference,
  });

  // the button will be active once all the fields are filled of prefilled data
  const [isNextButtonActive, setIsNextButtonActive] = useState(false);

  // manage accordion states
  const {
    toggleAccordionByKey,
    isOpen: isAccordionOpen,
    openFirstAccordion,
    openNextAccordion,
  } = useAccordion(ACCORDION_KEYS, additionalInformation, {
    openInitialAccordionDelay: 1500,
  });

  // open first accordion
  useEffect(() => {
    openFirstAccordion();
  }, []);

  useEffect(() => analyticsManager.sendEvent('lifestyle_pop_up_web', null, ['mixpanel']), []);

  useEffect(() => {
    setAdditionalInformation({
      drink_preference: drinkPreference,
      smoke_preference: smokePreference,
    });
  }, [queryStatus]);

  useEffect(() => {
    const isActive = Object.values(additionalInformation).every(
      (value) => value !== undefined && value !== '' && value !== null
    );
    setIsNextButtonActive(isActive);
  }, [additionalInformation]);

  // update global store state values based on the changed data and open next available accordion
  const updateGlobalStore = useCallback((key: typeof ACCORDION_KEYS[number], value: string) => {
    analyticsManager.sendEvent(
      'field_updates_web',
      { userId: user.id, fieldsupdated: key, valueSelected: value, cleverTap: 'yes' },
      ['mixpanel', 'clevertap']
    );

    setAdditionalInformation((prevData) => {
      const updatedGlobalStore = { ...prevData };
      updatedGlobalStore[key] = value;
      return updatedGlobalStore;
    });
    openNextAccordion(key, additionalInformation); // open next accordion
  }, []);

  // call the api with updated user data
  const mutateUserProfileOnBackend = () => {
    updateUserData({ ...additionalInformation });
    handleNext();
  };

  //Common props for all accordions
  const commonProps = {
    toggleAccordionByKey,
    updateGlobalStore,
  };

  return (
    <div className="relative h-full overflow-y-auto">
      {/* svg circle shape 👇 */}
      <div className="absolute top-0 right-0 z-[-1]">
        <Circle />
      </div>

      <motion.section
        initial="initial"
        animate="animate"
        variants={containerVariants}
        className="px-4 pt-12"
      >
        <motion.div variants={fadeInUp} className="relative top-0 w-1/2 left-4">
          <Image src={aiFace} alt="face of betterhalf-ai" />
        </motion.div>
        <motion.h1 variants={fadeInUp} className="my-2 text-2xl">
          Kudos on your journey so far!
        </motion.h1>
        <motion.p variants={fadeInUp} className="mt-2 mb-4 text-base font-thin">
          Our AI wants to know more to curate even better matches.
        </motion.p>

        {/* Drinks Accordion */}
        <Drinks
          {...commonProps}
          isAccordionOpen={isAccordionOpen('drink_preference')}
          savedValue={drinkPreference}
          accordionKey="drink_preference"
        />

        {/* smoke accordion */}
        <Smoke
          {...commonProps}
          isAccordionOpen={isAccordionOpen('smoke_preference')}
          savedValue={additionalInformation.smoke_preference}
          accordionKey="smoke_preference"
        />
      </motion.section>

      {/* Next button */}
      <Fab
        isDisabled={!isNextButtonActive}
        animate
        delayOnAnimation={1} // button will pop up when the lottie animation is finished appx after 6 seconds
        onClickHandler={() => mutateUserProfileOnBackend()}
        type="next"
      />
    </div>
  );
}
