import Accordion, { AccordionProps } from "@components/Accordion/Accordion";
import classNames from "classnames";
import { memo, useState } from "react";
import MaleIcon from "@assets/svg/male.svg";
import FemaleIcon from "@assets/svg/female.svg";
import MalePinkIcon from "@assets/svg/malePink.svg";
import FemalePinkIcon from "@assets/svg/femalePink.svg";
import { Gender as Gender } from "@libs/types";
import { useMutateUserData } from "@hooks/mutations";
import { capitalizeFirstLetter } from "@utils/formatter";
import useAnalyticsManager from "@hooks/useAnalyticsManager";

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: Gender;
  updateGlobalStore: (key, value) => void;
}

const Gender = ({ accordionKey, isAccordionOpen, toggleAccordionByKey, savedValue, updateGlobalStore }: Props) => {
  const [gender, setGender] = useState<Gender>(savedValue);
  const analyticsManager = useAnalyticsManager();
  const { mutate: updateUserData } = useMutateUserData();

  const props: Omit<AccordionProps, "children"> = {
    title: "What's your gender?",
    placeholder: "Eg: Male/Female",
    value: gender ? capitalizeFirstLetter(gender) : undefined,
    isOpen: isAccordionOpen,
    accordionKey,
    toggleAccordion: toggleAccordionByKey,
  };

  const onSelect = (genderType: Gender) => {
    setGender(genderType);
    updateGlobalStore(accordionKey, genderType);
    analyticsManager.sendEvent("gender_select_web", {cleverTap:'yes', genderSelected: genderType }, "all")
    //! FOR OPTIMIZATION : make the patch request to update the user data only if the user has changed the name
    if (savedValue !== genderType)
      updateUserData({
        gender: genderType,
      });
  };

  return (
    <Accordion {...props}>
      <div className="flex items-center w-[80%] mx-auto justify-between p-4">
        <GenderButton type="male" selectedType={gender} onSelect={onSelect} />
        <GenderButton type="female" selectedType={gender} onSelect={onSelect} />
      </div>
    </Accordion>
  );
};

export default memo(Gender);

interface GenderButtonProps {
  type: Gender;
  selectedType: Gender;
  onSelect: (type: Gender) => void;
}

const GenderButton = ({ type, selectedType, onSelect }: GenderButtonProps) => {
  return (
    <button
      onClick={() => onSelect(type)}
      tabIndex={0}
      className={classNames(
        "flex flex-col items-center space-y-3 focus:border-primaryPink cursor-pointer focus:outline-none hover:text-primaryPink",
        {
          "text-primaryPink": type === selectedType,
        }
      )}
    >
      <span className="grid bg-white  rounded-full shadow w-[80px] h-[80px] place-items-center">
        {type === "male" ? (
          type === selectedType ? (
            <MalePinkIcon />
          ) : (
            <MaleIcon />
          )
        ) : type === selectedType ? (
          <FemalePinkIcon />
        ) : (
          <FemaleIcon />
        )}
      </span>
      <span> {type === "male" ? "Male" : "Female"}</span>
    </button>
  );
};
