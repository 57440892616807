import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import classNames from 'classnames';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import female1 from '@assets/images/avatars/female1.png';
import female2 from '@assets/images/avatars/female2.png';
import male1 from '@assets/images/avatars/male1.png';
import male2 from '@assets/images/avatars/male2.png';
import { Button } from '@components/index';
import { ONBOARDING_STEPS_UI } from '@configs/onBoarding.config';
import { useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import useWindowDimensions from '@hooks/userViewportDimensions';

interface Props {
  showHeader?: boolean;
  carouselIndex: number;
  setCarouselIndex: Dispatch<SetStateAction<number>>;
  setOnboardingStep?: Dispatch<SetStateAction<number>>;
  showButton: boolean;
}
const StepPictureUploadTips: FC<Props> = ({
  carouselIndex,
  showHeader = true,
  setCarouselIndex,
  setOnboardingStep,
  showButton = true,
}) => {
  const analyticsManager = useAnalyticsManager();

  const { data: user } = useGetUser({
    enabled: false,
  });
  const { height: viewportHeight } = useWindowDimensions();

  useEffect(() => {
    analyticsManager.sendEvent('signup_sneak_peek_web', { userId: user?.id }, ['mixpanel']);
  }, []);

  const handleNext = (e) => {
    localStorage.setItem('lastOnboardingStep', String(ONBOARDING_STEPS_UI.stepPictureUploadTips));
    e.preventDefault();
    // getUserData()
    if (setOnboardingStep) {
      setOnboardingStep((step) => step + 1);

      analyticsManager.sendEvent(
        'continue_with_sneak_peek_web',
        { userId: user?.id, cleverTap: 'yes' },
        ['clevertap', 'mixpanel']
      );
    }
  };

  return (
    <form
      onSubmit={handleNext}
      className="relative z-10 flex flex-col flex-1 max-h-full pt-6 pb-4 "
    >
      <main className="flex flex-col flex-1 space-y-3">
        {showHeader && <h5>Tips to nail the perfect profile pictures</h5>}
        <div className="flex flex-col h-[60%] space-y-2">
          <Carousel
            emulateTouch
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            selectedItem={carouselIndex}
            showArrows={false}
            onChange={(itemIndex) => setCarouselIndex(itemIndex)}
          >
            <Image
              loading="eager"
              src={user?.gender === 'female' ? female1 : male2}
              alt="tip-1 : one person's picture , showing how to nail the perfect profile picture "
              objectFit="contain"
              priority
              quality={100}
              width={viewportHeight < 657 ? 270 : 380}
              height={viewportHeight < 657 ? 270 : 380}
            />

            <Image
              loading="eager"
              src={user?.gender === 'female' ? female2 : male1}
              alt="tip-2 : one person's picture , showing how to nail the perfect profile picture "
              priority
              objectFit="contain"
              quality={100}
              width={viewportHeight < 657 ? 270 : 380}
              height={viewportHeight < 657 ? 270 : 380}
            />
          </Carousel>
          <div className="space-x-2 text-center ">
            <span
              className={classNames('inline-block w-2 h-2 rounded-full ', {
                'bg-primaryPink': carouselIndex === 0,
                'border-primaryPink border': carouselIndex === 1,
              })}
            />
            <span
              className={classNames('inline-block w-2 h-2  rounded-full', {
                'border-primaryPink border': carouselIndex === 0,
                'bg-primaryPink': carouselIndex === 1,
              })}
            />
          </div>
          <p className="text-sm text-center">
            {carouselIndex === 0
              ? `Upload 3 or more photos that display your full face in good light. This will get
                        you 2X more quality conversations`
              : ` Upload genuine, recent photos of yours. It should not display nudity, scenery,
                        landscapes, celebrities, baby or animal pictures`}
          </p>
        </div>
      </main>
      {showButton && (
        <Button
          className="w-full"
          label="Continue"
          type="submit"
          spanId="onboarding-continue-photo-upload-tips"
        />
      )}
    </form>
  );
};
export default StepPictureUploadTips;
