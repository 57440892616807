import { memo, useEffect, useMemo, useState } from 'react';

import Accordion, { AccordionProps } from '@components/Accordion/Accordion';
import SaveButton from '@components/common/SaveButton';
import { useMutateUserData } from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import debounce from '@utils/debounce';
import { isValidEmail } from '@utils/validation';

import { Input } from '..';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
  onEmailChange: (value: string) => void;
}

const Email = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
  onEmailChange,
}: Props) => {
  const [email, setEmail] = useState(() => savedValue);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const { data: user } = useGetUser({
    enabled: false,
  });

  const { email: savedEmail } = user || {};

  const { mutate: updateUserData } = useMutateUserData();

  const props: Omit<AccordionProps, 'children'> = {
    title: 'Email',
    placeholder: 'Eg. sss@gmail.com',
    value: email,
    isOpen: isAccordionOpen,
    accordionKey,
    toggleAccordion: toggleAccordionByKey,
    isError: !isButtonEnabled,
  };

  useEffect(() => setIsButtonEnabled(isValidEmail(email)));

  const onSave = () => {
    updateGlobalStore(accordionKey, email);

    //! FOR OPTIMIZATION : make the patch request to update the user data only if the user has changed the name
    if (email !== savedEmail) updateUserData({ email });
  };

  const debouncedEmailValidation = useMemo(
    () =>
      debounce((value: string) => {
        const valid = isValidEmail(value);
        if (value.length > 4) setIsError(!valid);
        setIsButtonEnabled(valid);
      }, 500),
    []
  );

  const handleEmailChange = (value: string) => {
    setEmail(value);
    onEmailChange(value);
    debouncedEmailValidation(value);
  };

  return (
    <Accordion {...props}>
      <div className="flex flex-col flex-1 space-y-3 w-[98%] justify-center mx-auto pt-2">
        <Input
          placeholder="Add email address"
          setValue={handleEmailChange}
          value={email}
          errorMessage={isError && email ? 'Email is not valid!' : null}
          autoFocus
        />
        <p className="ml-0 text-xs text-grayIcon">
          We use this to recover your account if you can’t
        </p>
        <SaveButton isButtonEnabled={isButtonEnabled} handleNext={onSave} />
      </div>
    </Accordion>
  );
};

export default memo(Email);
