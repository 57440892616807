import { memo, useState } from 'react';

import { searchCommunityNames } from 'api/search';
import { BottomSheet } from 'react-spring-bottom-sheet';

import AccordionWithChips, {
  AccordionWithChipsProps,
} from '@components/Accordion/AccordionWIthChips';
import SearchList, { SearchListProps } from '@components/SearchList';
import { RELIGION_LIST } from '@configs/onBoarding.config';
import { Item } from '@libs/types';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
  religion?: any;
}

const getCasteBasedOnReligion = (religion: string): Item[] => {
  const CASTE_LIST = RELIGION_LIST.filter((val) => val.value !== 'any').find(
    (religionItem) => religionItem.value === religion
  )?.caste;
  return CASTE_LIST as Item[];
};

const Community = ({
  religion,
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(savedValue);
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithChipsProps = {
    accordionKey: accordionKey,
    list: getCasteBasedOnReligion(religion) as Item[],
    title: 'Community',
    placeholder: 'e.g. Agarwal',
    showSeeMore: true,
    onSeeMorePress: () => toggleBottomSheet(true),
    columnSize: 3,
    // Dynamic Props
    isOpen: isAccordionOpen,
    value: convertToStartCase(value),
    selectedItem: value,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  const KEY_TO_SEARCH_LIST_PROPS: SearchListProps = {
    onSelect: onItemSelect,
    onSearchCallback: searchCommunityNames,
    placeholder: 'Search Communities',
    initialData: getCasteBasedOnReligion(religion) as Item[],
    selectedData: value,
  };

  return (
    <>
      <AccordionWithChips {...props} />
      <BottomSheet open={isBottomSheetOpen} onDismiss={() => toggleBottomSheet(false)}>
        <SearchList {...KEY_TO_SEARCH_LIST_PROPS} />
      </BottomSheet>
    </>
  );
};

export default memo(Community);
