import { memo, useState } from 'react';

import { BottomSheet } from 'react-spring-bottom-sheet';

import AccordionWithChips, {
  AccordionWithChipsProps,
} from '@components/Accordion/AccordionWIthChips';
import LinkButton from '@components/Buttons/LinkButton';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey?: string;
  isAccordionOpen?: boolean;
  toggleAccordionByKey?: (key: string) => void;
  savedValue?: string;
  updateGlobalStore?: (key, value) => void;
}

/**
 * @desc Bio component to update bio / about of the user.
 */

const Bio = ({ accordionKey, isAccordionOpen, savedValue, updateGlobalStore }: Props) => {
  const [value, setValue] = useState(savedValue); // on change update value state
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false); // state managing bottomSheet toggle.

  // on save button click
  const onItemSelect = () => {
    updateGlobalStore(accordionKey, value); // update the global store state in parent component
    toggleBottomSheet(false); // close bottom Sheet
  };

  //  Accordion props
  const props: AccordionWithChipsProps = {
    title: 'Bio',
    placeholder: 'About',
    selectedItem: savedValue,
    value: convertToStartCase(savedValue),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: () => toggleBottomSheet(true), // at the place of opening theAccordion it will toggle the bottomSheet
  };

  //  handle input change
  const handleInputChange = (e) => {
    // updateGlobalStore(accordionKey, e.target.value);
    setValue(e.target.value); // set state with new value
  };

  const onBottomSheetClose = () => {
    setValue(savedValue);
    toggleBottomSheet(false);
  };

  return (
    <>
      {/* Accordion Section starts */}

      <AccordionWithChips {...props} />

      {/* Accordion section end */}

      {/* Bottom Sheet Containing the input box with initial values (changeable) and save button */}

      <BottomSheet open={isBottomSheetOpen} onDismiss={onBottomSheetClose}>
        <div className="mt-[10px] mb-[10px] items-center justify-content-center h-[400px]">
          <div className="justify-between p-[10px] ml-[12px] relative">
            <p className="text-[20px] font-[700] mt-[4px]  mr[12px] text-[rgb(51, 51, 51)]">
              {'Bio'}
            </p>
            <LinkButton
              className="absolute top-[22px] right-[12px] text-[16px] font-[700]"
              title="Save"
              onClickButton={() => onItemSelect()}
              isDisabled={value === savedValue}
            />
          </div>
          <div className="flex flex-col pb-10 space-y-4 pl-[20px] pr-[20px]">
            <textarea
              onChange={handleInputChange}
              className="h-[165px] rounded-[10px] border-[1px] border-gray-200  p-4"
              placeholder="Eg: I'm a spontaneous and good looking..."
              value={value}
            />
          </div>
        </div>
      </BottomSheet>

      {/* Bottom sheet ends here */}
    </>
  );
};

export default memo(Bio);
