import React from 'react';

// import "./Button.scss";
import cn from 'classnames';

import Loader from '@assets/svg/infinity-loader.svg';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  // Is this the principal call to action on the page?

  // Button contents
  label?: string;
  isLoading?: boolean;

  // When Button disabled
  disabled?: boolean;

  // With loader
  loader?: boolean;
  spanId?: string;
  className?: string;
}

/**
 * @description Button component
 * @param {ButtonProps} props
 */
//  https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/
const Button: React.FC<ButtonProps> = ({
  label = 'Button',
  disabled = false,
  className,
  type = 'button',
  isLoading,
  spanId,
  ...props
}) => {
  const storybook_button =
    'flex items-center justify-center px-5 space-x-1 py-3 text-lg font-bold rounded-full cursor-pointer shadow-lg text-white bg-primaryPink ' +
    className;

  return (
    <button
      //@ts-ignore : @
      type={type}
      className={cn(storybook_button, {
        'bg-opacity-50 bg-disabled': disabled,
      })}
      id={spanId}
      disabled={disabled}
      {...props}
    >
      {isLoading ? <Loader /> : <span id={spanId}>{label}</span>}
    </button>
  );
};
export default Button;
