/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { useGetUser } from '@hooks/queries';
import useReceivedRequestCount from '@hooks/useReceivedRequestsCount';
import { useGlobalStore } from '@zustand/store';

const Tab = ({ href, label, icon }) => {
  const { pathname } = useRouter();
  const { data: receiveRequestsCount } = useReceivedRequestCount();
  const [showSentBadge, setShowSentBadge] = useState(false);

  const active = pathname === href;

  const { queuedConnectionDate: queuedConnection, queuedVisitedDate } = useGlobalStore((state) => {
    return {
      queuedVisitedDate: state.queuedVisited,
      queuedConnectionDate: state.queuedConnection,
    };
  });

  const { data: user } = useGetUser({
    enabled: false,
  });

  useEffect(() => {
    if (!queuedVisitedDate && queuedConnection > 0) {
      setShowSentBadge(true);
    } else {
      setShowSentBadge(false);
    }
  }, [queuedConnection, queuedVisitedDate]);

  return (
    <Link href={href}>
      <div className="relative flex flex-col items-center justify-center cursor-pointer">
        {label === 'Received' && receiveRequestsCount?.count > 0 && (
          <div className="flex justify-center items-center absolute left-[30px] -top-[2px] rounded-lg bg-[#FF5B91] text-[#fff] w-[13px] h-[13px] text-[8px]">
            <p className="text-center text-[6px]">
              {receiveRequestsCount?.count > 99 ? '99+' : receiveRequestsCount?.count}
            </p>
          </div>
        )}
        {label === 'Sent' && showSentBadge && !user.isPaidUser && (
          // <div className="flex justify-center absolute left-5 -top-0 rounded-lg bg-[#FF5B91] text-[#fff] w-3 h-3 text-[8px] z-20">
          <span className="flex justify-center absolute left-5 -top-0 rounded-lg text-[#fff] w-3 h-3 text-[8px] z-20">
            <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-pingbg-[#FF5B91]"></span>
            <span className="relative inline-flex w-3 h-3 rounded-full bg-[#FF5B91]"></span>
          </span>
          // </div>
        )}
        <img
          alt="img"
          src={icon.selected}
          width={20}
          height={20}
          style={{ display: active ? 'block' : 'none' }}
        />
        <img
          alt="img"
          src={icon.unselected}
          width={20}
          height={20}
          style={{ display: active ? 'none' : 'block' }}
        />
        <a
          className={`tracking-[2.5px] pt-1 font-bold text-center text-black text-[7px] uppercase ${
            active ? 'text-primaryPink' : ''
          }`}
        >
          {label}
        </a>
      </div>
    </Link>
  );
};

export default Tab;
