import WhatsAppContact from '@components/WhatsAppContact';

export default function ContactUS({ message, number }: { message: string; number: string }) {
  return (
    <div className="flex justify-center mt-5 text-xs font-normal ">
      Need help?
      <WhatsAppContact message={message} number={number}>
        <p className="ml-1 font-semibold font-century-gothic text-primaryPink">Contact us</p>
      </WhatsAppContact>
    </div>
  );
}
