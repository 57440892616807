export const AI_TABS = [
  {
    id: 1,
    label: 'Matches',
    href: '/ai/matches',
    icon: {
      selected: '/assets/images/ai-tabs/matches_selected.svg',
      unselected: '/assets/images/ai-tabs/matches_unselected.svg',
    },
  },
  {
    id: 2,
    label: 'Received',
    href: '/ai/received',
    icon: {
      selected: '/assets/images/ai-tabs/received_selected.svg',
      unselected: '/assets/images/ai-tabs/received_unselected.svg',
    },
  },
  {
    id: 3,
    label: 'Sent',
    href: '/ai/sent',
    icon: {
      selected: '/assets/images/ai-tabs/sent_selected.svg',
      unselected: '/assets/images/ai-tabs/sent_unselected.svg',
    },
  },
  {
    id: 3,
    label: 'Chat',
    href: '/ai/chat',
    icon: {
      selected: '/assets/images/ai-tabs/chat_selected.svg',
      unselected: '/assets/images/ai-tabs/chat_unselected.svg',
    },
  },
];
