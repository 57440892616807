import { Item } from '@libs/types';

import Chip from '../Chip';
import Accordion, { AccordionProps } from './Accordion';

export type AccordionWithChipsProps = Omit<AccordionProps, 'children'> & {
  list?: Array<Item>;
  onItemSelect: ((item: Item) => void) | ((key: string, item: Item) => void);
  selectedItem: string | Item | undefined | string[];
  showSeeMore?: boolean;
  onSeeMorePress?: () => void; // TODO : add condition , if showSeeMore is true then onSeeMorePress is required
  columnSize?: 2 | 3;
  fontSizeSmall?: boolean;
  displayValue?: string | undefined;
  onSave?: () => void;
};

const AccordionWithChips = ({
  list,
  selectedItem,
  onItemSelect,
  showSeeMore,
  onSeeMorePress,
  columnSize = 2,
  displayValue,
  value,
  accordionKey,
  ...props
}: AccordionWithChipsProps) => {
  const chipsList = list?.map((data) => (
    <Chip
      key={data.id}
      onClickHandler={() => {
        props.toggleAccordion(accordionKey);
        // @ts-ignore : -
        onItemSelect(data);
      }}
      isSelected={
        selectedItem === data.value ||
        selectedItem === data ||
        // @ts-ignore : -
        selectedItem?.includes(data.displayText)
      }
      text={data.displayText}
      columnSize={columnSize}
    />
  ));
  return (
    <Accordion {...props} value={displayValue || value} accordionKey={accordionKey}>
      <div className="mt-4 mb-2">
        <div
          className={`grid  gap-y-2 gap-x-1 ${columnSize === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}
        >
          {chipsList}
        </div>
        {showSeeMore && (
          <button
            className="mt-4 ml-2 text-base text-center text-primaryPink"
            onClick={onSeeMorePress}
          >
            See more
          </button>
        )}
        {/* TODO : add see more button */}
      </div>
    </Accordion>
  );
};
export default AccordionWithChips;
