import { memo } from 'react';

import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';

import Tab from '@components/Tab';
import { AI_TABS } from '@components/Tab/config';
import { useGetUser } from '@hooks/queries';

const MAP_TABS_TO_PATH = {
  ai: AI_TABS,
  /**
   * Add other category tabs here when time comes
   * For example :
   * hm: HM_TABS
   */
};

const getTabsBasedOnPath = (path) => {
  for (const key in MAP_TABS_TO_PATH) {
    if (path.includes(key)) {
      return MAP_TABS_TO_PATH[key];
    }
  }
};

const Navbar = () => {
  const router = useRouter();
  const { pathname } = router;

  const tabsToRender = getTabsBasedOnPath(pathname);

  const { data: user } = useGetUser({
    enabled: false,
  });

  const { images } = user || {};

  const handleProfileClick = () => {
    router.push({ pathname: '/profile', query: { from: pathname } }, '/profile');
  };

  return (
    <motion.nav className="top-0 left-0 z-20 px-4 pt-4 pb-1 bg-white shadow h-[100px]">
      <div className="flex items-center justify-between w-full">
        <h6 className="text-black">AI matchmaking</h6>
        <Image
          onClick={handleProfileClick}
          src={images?.[0]}
          className="object-cover rounded-full cursor-pointer"
          alt="User Avatar"
          height={34}
          width={34}
        />
      </div>
      <div className="flex justify-between pt-2">
        {tabsToRender?.map((tab, index) => {
          const { href, label, icon } = tab;
          return <Tab key={index} href={href} label={label} icon={icon} />;
        })}
      </div>
    </motion.nav>
  );
};

export default memo(Navbar);
