export const formatNumber = (number: any): string =>
  number?.toString().length === 1 ? `0${number}` : number;

export const inchesToFtAndInch = ({ inches, withoutFtAndIn = true }) => {
  const feet = Math.floor(inches / 12);
  const inch = inches % 12;
  if (isEmpty(inches)) return '';
  if (withoutFtAndIn) return `${feet}' ${inch}"`;
  return `${feet} ft ${inch} in`;
};

export const inchesIntoCms = (inches: number) => {
  // @ts-ignore : -
  const cms = parseInt(inches * 2.54, 10);
  if (isEmpty(inches)) return '';
  return `${cms} cms`;
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return null;
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

const toCamel = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};
const isObject = function (obj) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const convertObjectKeysToCamelCase = function (obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = convertObjectKeysToCamelCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return convertObjectKeysToCamelCase(i);
    });
  }

  return obj;
};

export const convertToStartCase = (value: string) => {
  if (!value) return '';
  return value
    ?.split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

/**
 * Checks if the given value is an empty array, empty string or empty object.
 * Returns true by default if the type isn't any of the above mentioned types
 *
 * @param {*} value - any value
 * @return {boolean} true or false
 *
 * @example
 * isEmpty({}) // true
 * isEmpty([]) // true
 * isEmpty({name: 'John Doe'}) // false
 * isEmpty([1,2,3]) // false
 * isEmpty(undefined) // true
 */
export function isEmpty(value) {
  /* falsy value check */
  if (!value) {
    return true;
  }

  if (typeof value === 'number') {
    return false;
  }

  /* Array check */
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  /* String check */
  if (typeof value === 'string' || value instanceof String) {
    return ['null', ''].includes(value.trim());
  }

  /* Object check */
  if (value?.constructor.name === 'Object') {
    return Object.keys(value).length === 0;
  }

  /* fallback return for other types like Functions */
  return true;
}

export const capitalize = (s) => {
  if (isEmpty(s)) return '';
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const snakeToCamel = (str) =>
  str?.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ' '));

export const toSnakeCase = (str: string) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');
/**
 * Map an object with a property of snake_case keys to an object
 * with camelCase keys
 *
 * @param {Object} { keys: {snake_case: value}}
 * @return {Object} { keys: {camelCase: value}}
 *
 * @example
 * mapObjectKeysToCamelCase({keys: {first_name: 'John', last_name: 'Doe'}})
 * // {keys: {firstName: 'John', lastName: 'Doe'}}
 */
export const mapObjectKeysToCamelCase = ({ keys }) =>
  Object.keys(keys).reduce((accumulator, item) => {
    accumulator[snakeToCamel(item)] = keys[item];
    return accumulator;
  }, {});

/**
 * @description remove commas from a string
 */
export const removeCommas = (str: string) => str.replace(/,/g, '');

/**
 * @description remove underscore from a string
 */
export const removeUnderscore = (str: string) => capitalize(str.replace(/_/g, ' '));

/**
 * @description add underscore from a string
 */
export const addUnderscore = (str: string) => str.replace(/ /g, '_').toLowerCase();

/**
 * @description gets range object depending on the keys provided
 * @param data array of objects, keys: [array of keys]
 */
export const getRangeObject = ({ data, keys }) => {
  const result = {};

  keys.forEach((key) => {
    if (key === 'income') {
      result[key] = [data[`${key}From`] / 100000, data[`${key}To`] / 100000];
    } else {
      result[key] = [data[`${key}From`], data[`${key}To`]];
    }
  });
  return result;
};

/**
 * @description converts the array of numbers into an string
 * @param values array numbers, showText: boolean
 */
export const displaySliderValuesForAge = ({ values, showText = false }) => {
  if (isEmpty(values) || (values && !values.length) || (values && values.length === 1)) {
    return '';
  }

  return `${values[0]} - ${values[1]}${showText ? ' Years' : ''}`;
};

/**
 * @description converts the array of numbers into an string for income and adding 'Lpa' at the end
 * @param values array numbers, showText: boolean
 */
export const displaySliderValueForIncome = (incomes) => {
  if (isEmpty(incomes) || (incomes && !incomes.length) || (incomes && incomes.length === 1)) {
    return '';
  }

  return `${incomes[0]} - ${incomes[1] === 50 ? '50+' : incomes[1]} Lpa`;
};

/**
 * @description converts the array of numbers into inch and the to string
 * @param values array numbers, onlyIn: boolean
 */
export const heightConverterWithArray = ({ inches, onlyIn = false }) => {
  const feet = (val) => Math.floor(val / 12);
  const inch = (val) => val % 12;
  if (isEmpty(inches) || (inches && !inches.length) || (inches && inches.length === 1)) {
    return '';
  }

  const first = inches[0];
  const second = inches[1];
  if (onlyIn) return `${inch(first)} in - ${inch(second)} in`;

  return `${feet(first)}ft ${inch(first)}in - ${feet(second)}ft ${inch(second)}in`;
};

export const twoArrEqual = (arr1, arr2) => {
  if (arr1.length != arr2.length) return false;
  const sortedArray1 = arr1.sort();
  const sortedArray2 = arr2.sort();

  // Linearly compare elements
  for (let i = 0; i < sortedArray1?.length; i++)
    if (sortedArray1[i] != sortedArray2[i]) return false;

  // If all elements were same.
  return true;
};

export const formatSalaryToRange = ({ str, currency = 'INR' }) => {
  if (!str) return;
  if (currency === 'INR') {
    const incomeReceived = str.split('-');
    const incomeFrom = parseInt(incomeReceived[0], 10);
    const incomeTo = parseInt(
      incomeReceived[1].substring(0, incomeReceived[1].indexOf('Lpa') - 1),
      10
    );
    if (incomeFrom / 100 > 1 && incomeTo / 100 > 1) {
      return {
        income_from: incomeFrom,
        income_to: incomeTo,
      };
    }
    return {
      income_from: incomeFrom * 100000,
      income_to: incomeTo * 100000,
      currency: 'INR',
    };
  }
  const incomeReceived = str.split('-');
  const incomeFrom = parseInt(incomeReceived[0], 10);
  const incomeTo = parseInt(incomeReceived[1].substring(0, incomeReceived[1].indexOf('k') - 1), 10);
  if (incomeFrom / 1000 > 1 && incomeTo / 1000 > 1) {
    return {
      income_from: incomeFrom,
      income_to: incomeTo,
    };
  }
  return {
    income_from: incomeFrom * 1000,
    income_to: incomeTo * 1000,
    currency: 'USD',
  };
};

export const splitName = (name: string) => {
  if (!name)
    return {
      firstName: '',
      lastName: '',
    };
  const splitted = name.split(' ');
  const firstName = splitted.shift();
  const lastName = splitted.join(' ');
  return { firstName, lastName };
};

export const getFormattedDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  return dateString === 'not_decided'
    ? convertToStartCase(dateString)
    : dateString.split('-').reverse().join('/');
};

// Get display date value "Today" | "Yesterday" | date: "30 July, 2022",
function getDisplayDate(date) {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const compDate = new Date(year, month, day);
  const diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
  if (compDate.getTime() == today.getTime()) {
    return 'Today';
  } else if (diff <= 24 * 60 * 60 * 1000) {
    return 'Yesterday';
  } else {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(compDate);
    return formattedDate; // or format it what ever way you want
  }
}

// Group data based on date [{data: [{...}], dateGroup: 'Today'}, {...}];
export const arrangeDataByDate = (data) => {
  let currentGroup = getDisplayDate(data[0]?.created_at);
  const newDataList = [
    {
      data: [],
      dateGroup: getDisplayDate(data[0]?.created_at),
    },
  ];
  data?.map((i) => {
    if (getDisplayDate(i.created_at) !== currentGroup) {
      currentGroup = getDisplayDate(i?.created_at);
      newDataList.push({
        data: [i],
        dateGroup: getDisplayDate(i?.created_at),
      });
    } else {
      newDataList[newDataList.length - 1]?.data?.push(i);
    }
  });
  return newDataList;
};
