import Image from 'next/image';
import { useDragLayer } from 'react-dnd';

import { ItemTypes } from '@libs/types';

// Get Items style based on current offset and previous Offset
function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
export const CustomDragLayer = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    // get the item and offset details of the moved card
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case ItemTypes.CARD:
        return (
          <div className="relative col-span-6 p-2 bg-white rounded-full w-[102px] h-[102px] shadow">
            {
              <Image
                src={item?.data?.uploadedImageUrl || item?.data?.localImageUrl}
                alt="test"
                className="contain rounded-[50%]"
                width={100}
                height={100}
              />
            }
          </div>
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div className="fixed pointer-events-none z-[100] top-0 left-0 ">
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
};
