/* eslint-disable react/require-default-props */

/* eslint-disable react/no-unused-prop-types */
import { ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import ErrorIcon from '@assets/images/error.png';
import { fadeInUp } from '@configs/motion.config';

const CheckMark = dynamic(() => import('@components/icons/CheckMark'), {
  ssr: false,
});
export interface AccordionProps {
  accordionKey: string;
  isOpen: boolean;
  toggleAccordion: (accordionKey: string) => void;
  title: string;
  disableEnterAnimation?: boolean;
  value: string | null | undefined | string[] | [number, number];
  placeholder?: string;
  children: ReactNode;
  showErrorIcon?: boolean;
  showErrorWithDelay?: boolean;
  isError?: boolean;
}

const accordionBodyVariant: Variants = {
  collapsed: {
    height: 0,

    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
  open: {
    height: 'auto',
    transition: {
      ease: 'easeInOut',
    },
  },
};

const Accordion = ({
  children,
  toggleAccordion,
  accordionKey,
  isOpen,
  title,
  placeholder,
  value,
  isError,
}: AccordionProps) => {
  const [clientRendered, setClientRendered] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setClientRendered(true);
  }, []);

  useEffect(() => {
    if (isOpen) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [isOpen]);

  const getIcon = () => {
    if (value && !isOpen && clientRendered && !isError) {
      return <CheckMark />;
    }
    if (value && isError && !isOpen) {
      return <Image src={ErrorIcon} alt="error_icon" width={18} height={18} />;
    }
    return <ArrowDown isOpen={isOpen} />;
  };

  return (
    <motion.div
      ref={ref}
      className="border border-[#F5F5F5] rounded-lg mb-4 p-2 px-3 bg-white shadow-md "
      variants={fadeInUp}
      key={accordionKey}
    >
      {/* ACCORDION HEADER STARTS */}
      <button
        type="submit"
        className="flex flex-col w-full py-1 focus:outline-none "
        onClick={() => {
          toggleAccordion(accordionKey);
        }}
      >
        <div className="flex items-center justify-between w-full ">
          <h3 className="font-bold text-lg text-[#333333]">{title}</h3>
          {getIcon()}
        </div>
        <AnimatePresence>
          {!isOpen && clientRendered && (
            <motion.span
              className={classNames('text-base text-left', {
                'text-[#333333]': value,
                'text-[#C7C7C7]': !value,
              })}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.2,
                delay: 0.23,
                // ease: [0, 0.71, 0.2, 1.01]
              }}
            >
              {value || placeholder}
            </motion.span>
          )}
        </AnimatePresence>
      </button>
      {/* ACCORDION HEADER ENDS */}

      {/* ACCORDION BODY STARTS */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={accordionBodyVariant}
            className="overflow-hidden"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      {/* ACCORDION BODY ENDS */}
    </motion.div>
  );
};

export default Accordion;

interface ArrowDownProps {
  isOpen: boolean;
}

const ArrowDown = ({ isOpen }: ArrowDownProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-5 h-5"
    viewBox="0 0 20 20"
    fill="currentColor"
    initial={false}
    animate={{
      rotate: isOpen ? 180 : 0,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    }}
  >
    <path
      className=""
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </motion.svg>
);
