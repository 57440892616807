import { memo } from 'react';

import classNames from 'classnames';

import { Item } from '@libs/types';

import Chip from './Chip';

interface Props {
  list: Item[];
  onChipClickHandler: (item: Item) => void;
  columnSize?: 2 | 3;
  selectedChip?: Item | string | Array<Item> | string[];
  headerText?: string;
}
const ChipsContainer = ({
  list,
  onChipClickHandler,
  columnSize = 2,
  selectedChip,
  headerText,
}: Props) => {
  const checkIsSelected = (item: Item): boolean => {
    if (typeof selectedChip === 'string') {
      return selectedChip === item.value;
    }

    if (Array.isArray(selectedChip)) {
      const tempType = selectedChip as Array<Item>;
      return tempType.includes(item);
    }
    return selectedChip === item;
  };

  const chipsList = list?.map((data) => (
    <Chip
      key={data.id}
      onClickHandler={() => onChipClickHandler(data)}
      isSelected={checkIsSelected(data)}
      text={data.displayText}
      columnSize={columnSize}
    />
  ));
  return (
    <div className="px-4 py-4 ">
      {headerText && <h3 className="mb-4 text-xl">{headerText}</h3>}
      <div
        className={classNames('grid gap-y-2 gap-x-1', {
          'grid-cols-2': columnSize === 2,
          'grid-cols-3': columnSize === 3,
        })}
      >
        {chipsList}
      </div>
    </div>
  );
};

export default memo(ChipsContainer);
